import { AuthOptions, createAction, urls } from './Api';
import { useMutation } from 'react-fetching-library';

interface SignInQuery {
    access: string;
    email: string;
    password: string;
}

const createSignInAction = (query: SignInQuery) => {
    return createAction({
        method: 'POST',
        url: urls.signin,
        data: query,
        auth: AuthOptions.DISABLED
    });
};

export const useSignInQuery = () => {
    return useMutation(createSignInAction);
    // response:(
    //   {
    //      status: "verify"|"verified"
    //      token: string
    //      id: int
    //  }
    // ||
    // { /* httpError = 400 */
    //     status: "exists"|"denied"
    //     message: string
    // }
};
