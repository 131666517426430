import * as React from 'react';
import { useEffect } from 'react';
import { Page, PageSection, Split, SplitItem } from '@patternfly/react-core';

import { usePapersMutation, usePlotMutation, useSignInQuery } from '../Services';
import { SearchForm } from '../Components/SearchForm';
import { GraphData } from '../Components/Graph';
import { useDebounce } from 'react-use';
import { PapersQueryBase, PapersQueryProps } from '../Components/SearchForm/PapersQuery';
import { ModalAuth } from '../Components/ModalAuth';

import { Mixpanel } from '../Components/Mixpanel/MixpanelWrapper';
import { HumanTimeDiff } from '../Utils/Time';

import { AlertList } from '../Components/AlertList';
import { useSchedule } from '../Hooks/useSchedule';
import { HttpError, ServerErrorCode } from '../Utils/HttpError';

import { buildQueryParams, loadFromUrl } from '../Utils/ShareQuery';
import AuthContext from '../App/AuthContext';
import { OpenModalVariant, PubmedNavBar } from '../Components/Navbar/PubmedNavBar';
import { PubmedFooterBar } from '../Components/Navbar/PubmedFooterBar';
import { useGraphMessage } from '../Hooks/useGraphMessage';
import { useHistory, useParams } from 'react-router-dom';
import { Entities, Entity, EntityGroup } from '../Components/EntitiesMenu';
import { SelectedSearchElement, useSearchElementStash } from './Hooks/useSearchElementStash';
import { SearchElementType } from '../Types/Search';
import { PaperData } from '../Types/PaperData';
import { useSearchingContext } from './Hooks/useSearchingContext';
import ReadOnlyConfig from '../Config';
import { GraphSection } from './Main/GraphSection';
import { PlotResponse } from '../Types/Plot';
import { useGraphCallbacks } from './Main/useGraphCallbacks';
import { ViewerSection } from './Main/ViewerSection';
import { EntitiesSection } from './Main/EntitiesSection';
import { useAlerts } from './Main/useAlerts';
import LogRocket from 'logrocket';
import { useSourceContext } from './Context/Source/SourceContext';
import {useKeycloak} from "@react-keycloak/web";

interface BestPapersCompare {
    customdata: PaperData;
    importance: number;
}

const preloadedQueries = ReadOnlyConfig.appQueries;

const queryBaseToSearchElement = (query: PapersQueryBase): Array<SelectedSearchElement> => {
    let elements: Array<SelectedSearchElement> = [];
    let lastIndex = 0;

    elements = elements.concat(
        query.entities.map(e => ({
            id: e.id,
            displayName: e.displayName,
            type: SearchElementType.ENTITY,
            color: lastIndex++
        }))
    );

    elements = elements.concat(
        query.keywords.map((k) => ({
            id: k.toLowerCase(),
            displayName: k,
            type: SearchElementType.STRING,
            color: lastIndex++
        }))
    );

    return elements;
};

const initialQuery = (preloadedQueries && preloadedQueries.length > 0) ? preloadedQueries[0].pubmedQuery : '';
const initialKeywords: Array<SelectedSearchElement> = (preloadedQueries && preloadedQueries.length > 0) ?
    queryBaseToSearchElement(preloadedQueries[0]) :
    [];

type PaperCountData = {
    count: number;
    query: string;
}

if (parseInt(window.location.port) !== 3000)
{
    LogRocket.init('bckcct/scicarta');
}

export const Main: React.FunctionComponent = (_props) => {
    const loginLabel = 'Login';
    const logoutLabel = 'Logout';

    const searchingOp = useSearchingContext();
    const source = useSourceContext();

    const { keycloak } = useKeycloak();
    const isPremium = (keycloak.profile as any)?.attributes.is_premium === '1';

    React.useEffect(() => {
        // Base available sources
        const setAvailable = source.setAvailable;
        const select = source.select;

        const available = [ 'aacr_2023', 'asco_2023', 'aad_2023', 'eha_2023',
            'aacr_2022', 'ash_2022', 'asco_2022', 'sitc_2022',
            'asco_2021', 'aacr_2021', 'sitc_2021', 'aad_2022' ];
        setAvailable(available);
        select('asco_2023');

        // if (isPremium) {
        //     available.push(...[
        //         'eha_2023'
        //     ]);
        // }
        //
        // source.setAvailable(available);
        // // [ 'pubmed', 'clinical-trials',
        // // source.select('biorxiv');
        // // source.select('pubmed');
        // // source.select('clinical-trials');
        //
        // if (isPremium) {
        //     source.select('eha_2023');
        //     source.unselect('aacr_2023');
        // } else {
        //     source.select('aacr_2023');
        //     source.unselect('eha_2023');
        //     // source.select('aacr_2022')
        //     // source.select('aacr_2021');
        //     // eslint-disable-next-line react-hooks/exhaustive-deps
        // }

    }, [ isPremium, source.setAvailable, source.select ]);

    const { action } = useParams();
    const history = useHistory();

    const [ showModalNavBar, setShowModalNavBar ] =
        React.useState<OpenModalVariant.quickStartModal | OpenModalVariant.helpModal | undefined>(undefined);

    const plotMutation = usePlotMutation();
    const papersMutation = usePapersMutation();

    const [ oldMedQuery, setOldMedQuery ] = React.useState<string>('');
    const [ medQueryTime, setMedQueryTime ] = React.useState<number>(0);
    const [ medQuery, setMedQuery ] = React.useState<string>(initialQuery);

    const [ queryKeywords, setQueryKeywords ] = React.useState<Array<string>>([]);

    const [ papersCount, setPapersCount ] = React.useState<PaperCountData>();
    const [ plotResponse, setPlotResponse ] = React.useState<PlotResponse>({});
    const [ entitiesResponse, setEntitiesResponse ] = React.useState<Entities>([]);

    const [ paperToViewer, setPaperToViewer ] = React.useState<PaperData>({});
    const [ paperToRightViewer, setPaperToRightViewer ] = React.useState<PaperData>({});
    const [ papersClicked, setPapersClicked ] = React.useState<ReadonlyArray<PaperData>>([]);
    const [ isEditablePubmedQuery ] = React.useState<boolean>(true);

    const { token: authToken, setToken: setAuthToken } = React.useContext(AuthContext);
    const [ forceQueryUpdate, setForceQueryUpdate ] = React.useState<boolean>(false);

    const [ isModalOpen, setIsModalOpen ] = React.useState<boolean>(false);
    const [ authEmail, setAuthEmail ] = React.useState<string>('');
    const [ authPassword, setAuthPassword ] = React.useState<string>('');
    const [ authAccess, setAuthAccess ] = React.useState<string>('login');
    const [ authState, setAuthState ] = React.useState<string>('');
    const [ authButtonText, setAuthButtonText ] = React.useState<string>(loginLabel);
    const [ authButtonClass, setAuthButtonClass ] = React.useState<string>('blue');

    const [ activeTabKey, setActiveTabKey ] = React.useState<number>(1);

    const searchStash = useSearchElementStash(initialKeywords);
    const [ uiSearchStash, setUiSearchStash ] = React.useState<Array<SelectedSearchElement>>(searchStash.elements);

    const { graphMessage, resetGraphMessage, setGraphMessageFound0Papers, setGraphMessageSuccess } = useGraphMessage(authToken);

    const selectAllPapers = React.useCallback(() => {
        setPapersClicked(prev => {
            const newSelected = Object.fromEntries(prev.map(p => [ p.pmid, p ]));

            if (plotResponse.data) {
                for (const section of plotResponse.data) {
                    for (const paper of section.customdata) {
                        newSelected[paper.pmid] = paper;
                    }
                }
            }

            return Object.values(newSelected);
        });
        console.log('response', plotResponse.data);
    }, [ setPapersClicked, plotResponse ]);

    const errorCallback = React.useCallback((httpError: HttpError) => {
        if (httpError.error.code === ServerErrorCode.QUERY_YIELDS_ZERO_PAPERS) {
            setGraphMessageFound0Papers(medQuery);
            return true;
        }

        return false;
    }, [ setGraphMessageFound0Papers, medQuery ]);

    const {
        processError,
        errorList,
        removeAlert
    } = useAlerts(errorCallback);

    const graphCallbacks = useGraphCallbacks(
        setPaperToViewer,
        setPlotResponse,
        setPapersClicked,
        setPaperToRightViewer
    );

    useDebounce(() => {
        if (medQuery !== '') {
            Mixpanel.track({
                name: 'Request Papers Number',
                props: {
                    medQuery
                }
            });

            papersMutation.mutate({
                paperQuery: medQuery,
                sources: source.selected.join(',')
            }).then((response) => {
                if (!response.error) {
                    setPapersCount({
                        count: parseInt(response.payload?.papers_count),
                        query: medQuery
                    });
                } else {
                    processError({
                        status_code: response.status,
                        error: response.payload
                    }, 'Request Papers Number');
                }
            });
        } else {
            setPapersCount(undefined);
        }
    }, 200, [
        medQuery, papersMutation.mutate, source
    ]);

    const plotMutationExecute = plotMutation.mutate;
    const plotMutationAbort = plotMutation.abort;

    const setBestPaper = React.useCallback((data: GraphData[]) => {
        const dataElements: BestPapersCompare[] = [];
        for (const trace of data) {
            for (const j in trace.customdata) {
                const index = parseInt(j);

                if (Number.isInteger(index)) {
                    const importance = parseFloat((trace.y && trace.y[index]) ? trace.y[index] + '' : '0');
                    const citation = parseInt(trace.customdata[index].citation + '');
                    dataElements.push({
                        customdata: trace.customdata[index],
                        importance: importance * citation
                    });
                }
            }
        }

        const orderData = (a: BestPapersCompare, b: BestPapersCompare) => {
            if (a.importance < b.importance) {return 1;}

            if (a.importance > b.importance) {return -1;}

            return 0;
        };

        dataElements.sort(orderData);

        if (dataElements.length > 0) {
            const bestPaper = dataElements[0].customdata;
            setPaperToViewer(bestPaper);
        }
    }, [ ]);

    const scheduleQueryCallback = React.useCallback(() => {
        if (medQuery.trim() === '') {
            return;
        }

        if (!papersCount || papersCount.count === 0 || papersCount.query !== medQuery) {
            setPlotResponse({});
            plotMutationAbort();
            return;
        }

        if (searchingOp.shouldLoad(medQuery, searchStash.elements, source.selected) || forceQueryUpdate) {
            const requestObj = {
                searchTerm: medQuery,
                keywords: searchStash.elements.filter(se => se.type === SearchElementType.STRING).map(se => se.id).join(','),
                entities: searchStash.elements.filter(se => se.type === SearchElementType.ENTITY).map(se => se.id).join(','),
                sources: source.selected.join(',')
            };

            const initRequestTime = Date.now();

            if (medQuery !== oldMedQuery) {
                if (medQueryTime > 0) {
                    Mixpanel.track({
                        name: 'Search terms are changed',
                        props: {
                            oldMedQuery,
                            newMedQuery: medQuery,
                            timeLapse: HumanTimeDiff.fromMillis(Date.now() - medQueryTime).toString()
                        }
                    });
                }

                setMedQueryTime(Date.now());
            }

            Mixpanel.track({
                name: 'Init Paper\'s data request',
                props: requestObj
            });

            const urlQueryParam = buildQueryParams({
                searchElements: searchStash.elements,
                pubmedQuery: medQuery,
                sources: source.selected
            });

            resetGraphMessage();
            plotMutationExecute(requestObj).then(response => {
                Mixpanel.track({
                    name: 'End Paper\'s data request',
                    props: {
                        timeLapse: HumanTimeDiff.fromMillis(Date.now() - initRequestTime).toString(),
                        ...requestObj
                    }
                });

                if (!response.error && response.payload?.data !== undefined) {

                    setGraphMessageSuccess(response.payload.missing_keywords, response.payload.threshold_reached);
                    if (response.payload.query_keywords) {
                        setQueryKeywords(response.payload.query_keywords);
                    }

                    setUiSearchStash(searchStash.elements);

                    if (response.payload.entities) {
                        const entitiesPayload = response.payload.entities;
                        const entities: Array<EntityGroup | Entity> = [];

                        const entityProcessor = (contents: Array<EntityGroup | Entity>, key: string, value: any) => {
                            const entityGroup: EntityGroup = {
                                type: 'group',
                                name: key,
                                contents: []
                            };
                            contents.push(entityGroup);

                            if (value.length) {
                                (value as Array<any>).forEach(element => {
                                    entityGroup.contents.push({
                                        type: 'entity',
                                        name: entitiesPayload.canonicalNames[element.conceptId],
                                        words: element.words,
                                        count: element.count,
                                        id: element.conceptId
                                    });

                                    // Only sort when all the elements are entity
                                    if (entityGroup.contents.every(a => a.type === 'entity')) {
                                        entityGroup.contents.sort((a, b) => {
                                            if (a.type !== b.type) {
                                                if (a.type === 'entity') {
                                                    return -1;
                                                } else {
                                                    return 1;
                                                }

                                            } else if (a.type === 'entity' && b.type === 'entity') {
                                                return b.count - a.count;
                                            } else {
                                                return a.name.localeCompare(b.name);
                                            }
                                        });
                                    }
                                });
                            } else {
                                Object.keys(value).forEach(group => {
                                    entityProcessor(entityGroup.contents, group, value[group]);
                                });
                            }
                        };

                        Object.keys(entitiesPayload.entities).forEach(group => {
                            entityProcessor(entities, group, entitiesPayload.entities[group]);
                        });

                        setEntitiesResponse(entities);
                    }

                    const responseData = {
                        data: response.payload?.data.map((d: any) => {
                            return {
                                ...d,
                                customdata: d.customdata.map((c: any, index: number) => {
                                    const date = new Date(d.x[index]);
                                    const formattedDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;

                                    return {
                                        ...c,
                                        date: formattedDate,
                                        citation: c.citation ? c.citation.toString() : c.citation
                                    };
                                })
                            };
                        }),
                        layout: response.payload?.layout,
                        foundPapers: response.payload?.found_papers,
                        medQuery: requestObj.searchTerm
                    };

                    setPlotResponse(responseData);
                    setBestPaper(responseData.data);

                    searchingOp.finishLoad(medQuery, searchStash.elements, source.selected);

                    return responseData;
                } else {
                    processError({
                        status_code: response.status,
                        error: response.payload
                    }, 'Paper\'s data request');
                }
            });

            setOldMedQuery(medQuery);
            setForceQueryUpdate(false);

            if (history.location.search !== urlQueryParam) {
                history.push({
                    pathname: '/',
                    search: urlQueryParam
                });
            }
        }
    }, [
        medQueryTime,
        setMedQueryTime,
        plotMutationExecute,
        plotMutationAbort,
        processError,
        setBestPaper,
        medQuery,
        forceQueryUpdate,
        setForceQueryUpdate,
        oldMedQuery,
        setOldMedQuery,
        resetGraphMessage,
        setGraphMessageSuccess,
        history,
        searchStash.elements,
        searchingOp,
        papersCount,
        source
    ]);

    const scheduleQuery = useSchedule(scheduleQueryCallback);

    React.useEffect(() => {
        if (papersCount) {
            scheduleQuery();
        }
    }, [ scheduleQuery, papersCount, medQuery, searchStash.elements, source.selected ]);

    React.useEffect(() => {
        const query = loadFromUrl(window.location.href);
        const setKeywords = searchStash.set;
        const select = source.select;
        const selected = source.selected;
        const unselect = source.unselect;
        if (query) {
            setMedQuery(query.pubmedQuery);
            setKeywords(query.searchElements);
            selected.forEach(unselect);
            query.sources.forEach(select);
        }

        scheduleQuery();
    }, []); // Only on load

    React.useEffect(() => {
        scheduleQuery();
        setForceQueryUpdate(true);
    }, [ authToken, scheduleQuery ]);

    const onAuthClose = React.useCallback(() =>  {
        if (authButtonText === logoutLabel) {
            setAuthButtonText(loginLabel);
            setAuthToken('');
            setForceQueryUpdate(true);
            Mixpanel.reset();
        } else {
            setIsModalOpen(!isModalOpen);
        }

        return isModalOpen;
    }, [ isModalOpen, setIsModalOpen, authButtonText, setAuthToken, setForceQueryUpdate ]);

    const signInMutate = useSignInQuery();

    const onAuth = React.useCallback(() =>  {
        setIsModalOpen(!isModalOpen);
        const signInQuery = {
            access: authAccess,
            email: authEmail,
            password: authPassword
        };
        signInMutate.mutate(signInQuery).then((response) => {
            if (!response.error) {
                setAuthState(response.payload?.state);
                if (response.payload?.state === 'verify') {
                    setAuthButtonText(logoutLabel);
                    if (response.payload.id) {
                        Mixpanel.regist({
                            id: response.payload.id,
                            email: authEmail
                        });
                    }

                    setAuthToken(response.payload?.token);
                } else if (response.payload?.state === 'verified') {
                    setAuthButtonText(logoutLabel);
                    if (response.payload.id) {
                        Mixpanel.regist({
                            id: response.payload.id,
                            email: authEmail
                        });
                    }

                    setAuthToken(response.payload?.token);
                } else if (response.payload?.state === 'denied') {
                    processError({
                        status_code: response.status,
                        error: response.payload
                    }, 'Email password combination is incorrect');
                } else if (response.payload?.state === 'exists') {
                    processError({
                        status_code: response.status,
                        error: response.payload
                    }, 'Account already created with this email please try to login');
                } else {
                    setAuthButtonText(loginLabel);
                }
            } else {
                processError({
                    status_code: response.status,
                    error: response.payload
                }, 'Attention');
            }
        });
        return isModalOpen;
    }, [ authAccess, authEmail, authPassword, setAuthToken, setAuthButtonText, isModalOpen, processError, signInMutate ]);

    const addNewSearch = React.useCallback((newSearchTerm: string, query: PapersQueryBase) => {
        const setKeywords = searchStash.set;
        setMedQuery(newSearchTerm);
        setKeywords(queryBaseToSearchElement(query));
    }, [ setMedQuery, searchStash.set ]);

    const papersQuery: PapersQueryProps = {
        query: medQuery,
        onChange: setMedQuery,
        count: papersCount?.count,
        loading: papersMutation.loading,
        placeholderSearchTerm: ReadOnlyConfig.placeholderSearchTerm,
        scheduleQuery
    };

    useEffect(() => {
        scheduleQuery();
    }, [ scheduleQuery ]);

    useEffect(() => {
        const helpModalActions = [ 'help', 'instructions' ];
        if (action) {
            if (helpModalActions.includes(action.toLowerCase())) {
                setShowModalNavBar(OpenModalVariant.helpModal);
            } else {
                history.push('/');
            }
        }
    }, [ action, history, setShowModalNavBar ]);

    useEffect(() => {
        if (authState !== 'verified' && authState !== 'verify') {
            setAuthButtonClass('green');
        } else {
            setAuthButtonClass('blue');
        }
    }, [ authState, setAuthButtonClass ]);

    return (
        <>
            <Page header={ <PubmedNavBar showModal={ showModalNavBar } /> } className="page" >
                <ModalAuth
                    isModalOpen = { isModalOpen }
                    setIsModalOpen = { setIsModalOpen }
                    authEmail = { authEmail }
                    setAuthEmail = { setAuthEmail }
                    authPassword = { authPassword }
                    setAuthPassword = { setAuthPassword }
                    setAuthAccess = { setAuthAccess }
                    onAuth = { onAuth }
                />
                <AlertList alerts={ errorList } removeAlert={ removeAlert } />
                <PageSection variant="light" className="page-section" >
                    <EntitiesSection
                        entities={ entitiesResponse }
                        stash={ searchStash }
                    />
                    <Split>
                        <SplitItem isFilled key={ Object.keys(entitiesResponse).length === 0 ? 'empty' : 'stuff' }>
                            <PageSection variant="light" className="page-section scicarta-search" >
                                <SearchForm
                                    papersQuery={ papersQuery }
                                    addSearchElement={ searchStash.add }
                                    removeSearchElement={ searchStash.remove }
                                    searchElements={ searchStash.elements }
                                    onAccess={ onAuthClose }
                                    scheduleQuery={ scheduleQuery }
                                    downloadedCount={ plotResponse.foundPapers }
                                    loading={ plotMutation.loading }
                                    access={ true }
                                    loginButtonText={ authButtonText }
                                    loginButtonClass={ authButtonClass }
                                    addNewSearch={ addNewSearch }
                                    preloadedQueries={ preloadedQueries }
                                    isEditablePubmedQuery={ isEditablePubmedQuery }
                                />
                            </PageSection>
                            <GraphSection
                                message={ graphMessage }
                                isLoading={ plotMutation.loading }
                                onHover={ graphCallbacks.onHover }
                                onUpdate={ graphCallbacks.onUpdate }
                                onClick={ graphCallbacks.onClick }
                                papers={ papersClicked }
                                selectedSearchElements={ uiSearchStash }
                                plot={ plotResponse }
                            />
                            <ViewerSection
                                papers={ papersClicked }
                                setPapers={ setPapersClicked }
                                leftPaper={ paperToViewer }
                                setLeftPaper={ setPaperToViewer }
                                rightPaper={ paperToRightViewer }
                                activeTabKey={ activeTabKey }
                                setActiveTabKey={ setActiveTabKey }
                                queryKeywords={ queryKeywords }
                                searchStash={ uiSearchStash }
                                onSelectAllPapers={ selectAllPapers }
                            />
                        </SplitItem>
                    </Split>
                </PageSection>
            </Page>
            <PubmedFooterBar />
        </>
    );
};
