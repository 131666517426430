import { AuthOptions, createAction } from './Api';
import { useMutation } from 'react-fetching-library';
import { urls } from './Api';

interface VerifyQuery {
    token: string;
}

const createVerifyAction = (query: VerifyQuery) => {
    return createAction({
        method: 'GET',
        url: urls.verify(query.token),
        auth: AuthOptions.DISABLED
    });
};

export const useVerifyMutation = () => {
    return useMutation(createVerifyAction);
};
