import * as React from 'react';

import { PaperViewerPart } from './PaperViewer/PaperViewerPart';
import { PaperViewerInLine } from './PaperViewer/PaperViewerInLine';
import { PaperViewerTitle } from './PaperViewer/PaperViewerTitle';
import { style } from 'typestyle';
import { StyledChunks, StylesWords } from '../Utils/HighLightReconciliation';
import { ColorPalette } from '../Utils/Color';
import { SelectedSearchElement } from '../Pages/Hooks/useSearchElementStash';
import { SearchElementType } from '../Types/Search';
import { EntitiesSection, PaperData } from '../Types/PaperData';

interface ViewerProps {
    paper?: PaperData;
    searchElements: ReadonlyArray<SelectedSearchElement>;
    queryKeywords: ReadonlyArray<string>;
}

const paperViewerClassName = style({
    textAlign: 'justify',
    margin: 16
});

const selectedSearchElementToHighlight = (selectedSearchElements: ReadonlyArray<SelectedSearchElement>) => {
    return selectedSearchElements.map(k => ({
        searchWords: [ k.displayName ],
        style: {
            color: ColorPalette.getColor(k.color).pastel.toString()
        }
    })).reduce((res: Array<StylesWords>, cur) => {
        const found = res.find(e => e.style.color === cur.style.color);
        if (found) {
            found.searchWords = found.searchWords.concat(cur.searchWords);
        } else {
            res.push(cur);
        }

        return res;
    }, []);
};

export const PaperViewer: React.FunctionComponent<ViewerProps> = (props) => {
    const { author } = (props.paper) ? props.paper : { author: undefined };

    const allAuthors = React.useMemo(() => {
        const allAuthors = author ? author : '';
        return allAuthors.split(',').map(item => item.trim()).join(', ');
    }, [ author ]);

    const searchToHighlight = React.useMemo((): Array<StylesWords> => {

        const keywordsStyleWords = selectedSearchElementToHighlight(props.searchElements);

        const queryStyleWords: ReadonlyArray<StylesWords> = [
            {
                searchWords: props.queryKeywords,
                style: {
                    bold: true
                }
            }
        ];

        return queryStyleWords.concat(keywordsStyleWords);
    }, [ props.searchElements, props.queryKeywords ]);

    const searchToHighlightForAbstract = React.useMemo((): Array<StylesWords> => {
        const keywordsStyleWords = selectedSearchElementToHighlight(props.searchElements.filter(value => value.type === SearchElementType.STRING));
        const queryStyleWords: Array<StylesWords> = [
            {
                searchWords: props.queryKeywords,
                style: {
                    bold: true
                }
            }
        ];

        return queryStyleWords.concat(keywordsStyleWords);
    }, [ props.searchElements, props.queryKeywords ]);

    const styledChunk = React.useMemo((): Array<StyledChunks> => {
        const chunks: Array<StyledChunks> = [];
        const entitiesSection: EntitiesSection = props.paper?.entities ?? {};

        for (const key in entitiesSection) {
            const entity = props.searchElements.find(e => e.type === SearchElementType.ENTITY && e.id === key);
            if (entity) {
                chunks.push(...entitiesSection[key].map(es => ({
                    start: es.start,
                    end: es.end,
                    style: {
                        color: ColorPalette.getColor(entity.color).pastel.toString()
                    }
                })));
            }
        }

        return chunks;
    }, [ props.searchElements, props.paper ]);

    if (props.paper && props.paper.pmid && props.paper.pmid.startsWith('NCT')) {
        let trial_status = '';
        if (props.paper.phase !== undefined)
        { trial_status = 'Phase: ' + props.paper.phase;}

        if (props.paper.status !== undefined)
        { trial_status = trial_status + ' &nbsp; - &nbsp; ' + props.paper.status; }

        if (props.paper.why_stopped !== undefined && props.paper.why_stopped)
        { trial_status = trial_status + ' &nbsp; - &nbsp;  Why stopped: ' + props.paper.why_stopped; }

        // Todo: Remove once the index has been fixed
        // ------------------------------------------
        const filterChunk = (offset: number, length: number, original: Array<StyledChunks>, text: string) => {
            return original.filter(v => v.start >= offset && v.end <= offset + length).map(v => {
                const start = v.start - offset;
                const end = v.end - offset;
                const newLines = text.slice(offset, v.start).split('\\n').length - 1;
                return {
                    ...v,
                    start: start - newLines,
                    end: end - newLines
                };
            });
        };

        // The style highlighter is computed by the json abstract
        const briefSummaryStart = '{\'BriefSummary\': \''.length;
        const briefSummaryLength = props.paper.brief_summary?.length !== undefined ?
            props.paper.brief_summary.length + props.paper.brief_summary.split('\n').length - 1 :
            0;

        const detailStart = briefSummaryStart + briefSummaryLength + '\', \'DetailedDescription\': \\"'.length;
        const detailLength = props.paper.detailed_description?.length ?? 0;

        const briefStyledChunk = filterChunk(briefSummaryStart, briefSummaryLength, styledChunk, props.paper.abstract ?? '');
        const detailChunk = filterChunk(detailStart - 1, detailLength, styledChunk, props.paper.abstract ?? '');

        // ---------------------------------------------

        // TODO: Bolding and entity highlighting are both disabled in "Detailed Description" and "Brief Summary":
        //       Not passing styledChunks and searchToHighlight to "Detailed Description" and "Brief Summary"
        //       Using searchToHighlight and styledChunks twice incorrectly bolds occurrences and highlights entities.
        //       I suspect there must be some shared variable
        return (
            <div className={ paperViewerClassName }>
                <PaperViewerTitle label="Sponsor: " paper={ props.paper } renderLink={ true } searchToHighlight={ searchToHighlight }/>
                <PaperViewerInLine label="Paper id" text={ props.paper.pmid  } searchToHighlight={ searchToHighlight } />
                <PaperViewerInLine label="Status" text={ trial_status } searchToHighlight={ searchToHighlight } />
                <PaperViewerPart
                    label="Brief Summary"
                    text={ props.paper.brief_summary }
                    searchToHighlight={ searchToHighlightForAbstract }
                    processComma={ false }
                    styledChunks={ briefStyledChunk } // Todo: use styledChunk once the index is fixed
                />
                <PaperViewerPart
                    label="Detailed Description"
                    text={ props.paper.detailed_description }
                    searchToHighlight={ searchToHighlightForAbstract }
                    processComma={ false }
                    styledChunks={ detailChunk } // Todo: use styledChunk once the index is fixed
                />
                <PaperViewerPart label="MeSH" text={ props.paper.mesh } searchToHighlight={ searchToHighlight } processComma={ true } />
            </div>
        );
    } else { if (props.paper && props.paper.pmid && props.paper.pmid.includes('.')) {
        return (
            <div className={ paperViewerClassName }>
                <PaperViewerTitle label="First, Last Authors: "  paper={ props.paper } renderLink={ true } searchToHighlight={ searchToHighlight }/>
                <PaperViewerInLine label="Paper id" text={ props.paper.pmid  } searchToHighlight={ searchToHighlight } />
                <PaperViewerInLine label="Preprint" text={ 'BioR<i>x</i>iv' } searchToHighlight={ searchToHighlight } />
                <PaperViewerInLine label="Company Affiliations" text={ props.paper.affiliation } searchToHighlight={ searchToHighlight } />
                <PaperViewerPart
                    label="Abstract"
                    text={ props.paper.abstract }
                    searchToHighlight={ searchToHighlightForAbstract }
                    styledChunks={ styledChunk }
                    processComma={ false }
                />
                <PaperViewerInLine label="All Authors" text={ allAuthors } searchToHighlight={ searchToHighlight } />
                <PaperViewerPart label="Keywords"  text={ props.paper.keywords } searchToHighlight={ searchToHighlight } processComma={ true }/>
                <PaperViewerPart
                    label="Publication Type"
                    text={ props.paper.publication_type }
                    searchToHighlight={ searchToHighlight }
                    processComma={ true }/>
                <PaperViewerPart label="MeSH"  text={ props.paper.mesh } searchToHighlight={ searchToHighlight } processComma={ true } />
                <PaperViewerPart label="Substances"  text={ props.paper.substance } searchToHighlight={ searchToHighlight } processComma={ true } />
            </div>
        );
    } else if (props.paper && props.paper.title !== undefined) {
        return (
            <div className={ paperViewerClassName }>
                <PaperViewerTitle label="First, Last Authors: "  paper={ props.paper } renderLink={ true } searchToHighlight={ searchToHighlight }/>
                <PaperViewerInLine label="Journal" text={ props.paper.journal } searchToHighlight={ searchToHighlight } />
                <PaperViewerInLine label="Paper id" text={ props.paper.pmid  } searchToHighlight={ searchToHighlight } />
                <PaperViewerInLine label="Company Affiliations" text={ props.paper.affiliation } searchToHighlight={ searchToHighlight } />
                <PaperViewerPart
                    label="Abstract"
                    text={ props.paper.abstract }
                    searchToHighlight={ searchToHighlightForAbstract }
                    styledChunks={ styledChunk }
                    processComma={ false }
                />
                <PaperViewerInLine label="All Authors" text={ allAuthors } searchToHighlight={ searchToHighlight } />
                <PaperViewerPart label="Keywords"  text={ props.paper.keywords } searchToHighlight={ searchToHighlight } processComma={ true }/>
                <PaperViewerPart label="Publication Type"
                    text={ props.paper.publication_type }
                    searchToHighlight={ searchToHighlight }
                    processComma={ true }/>
                <PaperViewerPart label="MeSH"  text={ props.paper.mesh } searchToHighlight={ searchToHighlight } processComma={ true } />
                <PaperViewerPart label="Substances"  text={ props.paper.substance } searchToHighlight={ searchToHighlight } processComma={ true } />
            </div>
        );
    }
    }

    return null;

};
