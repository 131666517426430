import * as React from 'react';
import { HighlightText } from '../../Utils/HighLightText';
import { StyledChunks, StylesWords } from '../../Utils/HighLightReconciliation';

interface ViewerProps {
    label?: string;
    text?: string;
    higthlighting?: boolean;
    searchToHighlight? : Array<StylesWords>;
    styledChunks?: Array<StyledChunks>;
}

export const PaperViewerInLine: React.FunctionComponent<ViewerProps> = (props) => {
    const isHigthLighting = (props.higthlighting === undefined || props.higthlighting);
    const textStr = (props.text === undefined) ? '' : props.text;

    return (
        <>
            <p>
                <strong className='label-text'>{ props.label }</strong>:&nbsp;
                { isHigthLighting ? (
                    <HighlightText component={ 'span' } text={ textStr } keywords={ props.searchToHighlight } styledChunks={ props.styledChunks } />
                ) : (
                    <span>{ textStr }</span>
                )
                }
            </p>
        </>
    );
};
