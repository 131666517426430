import * as React from 'react';
import { HighlightText } from '../../Utils/HighLightText';
import { StyledChunks, StylesWords } from '../../Utils/HighLightReconciliation';
import { PaperData } from '../../Types/PaperData';

interface ViewerProps {
    label?: string;
    paper: PaperData;
    renderLink?: boolean;
    higthlighting?: boolean;
    searchToHighlight? : Array<StylesWords>;
    styledChunks?: Array<StyledChunks>;
}

export const PaperViewerTitle: React.FunctionComponent<ViewerProps> = (props) => {
    const titleStr = (props.paper.title === undefined) ? '' : props.paper.title;

    const strongTitle =  <HighlightText
        component={ 'strong' } text={ titleStr }  keywords={ props.searchToHighlight } styledChunks={ props.styledChunks }
    />;
    let linkTitle =  <a
            href={props.paper.doi }
            rel="noopener noreferrer"
            target="_blank"
        >
            { strongTitle }
        </a>;
    //FIXME: This should be done int the backend
    let authors = props.paper?.author;
    if (authors) {
        const author_array = authors.split(',');
        if (author_array.length >= 2) {
            authors = [ author_array[0], author_array.slice(-1)[0] ].join(', ');
        }
    } else {
        authors = '';
    }

    const date = props.paper?.date ? props.paper?.date : '';
    //FIXME: {date ? " - " : ''  } is to remove trailing dash due to "missing date bug" the first time we load the app

return (
            <>
                { linkTitle }
                <p>
                    <strong className='label-text'>{ props.label }</strong>
                    <HighlightText component={ 'span' } text={ authors } keywords={ props.searchToHighlight } styledChunks={ props.styledChunks } />
                    { date ? '  -   ' : '' }
                    { date }
                    {/*{ (props.paper && props.paper.pmid && props.paper.pmid.startsWith('NCT')) ? '' : <strong className='label-text'>   - id: </strong>}*/}
                    {/*{ (props.paper && props.paper.pmid && props.paper.pmid.startsWith('NCT')) ? '' : props.paper.pmid }*/}
                </p>
            </>
        )
}