import { AlertData } from '../Components/AlertList';
import { AlertVariant } from '@patternfly/react-core';

export interface HttpError {
    status_code: number | undefined;
    error: any;
}

export interface ServerError {
    title: string;
    message: string;
    code: ServerErrorCode;
    payload: any;
}

export interface HttpServerError extends HttpError {
    error: ServerError;
}

export const isServerError = (httpError: HttpError): httpError is HttpServerError => {
    return httpError.error &&
        httpError.error.message &&
        typeof httpError.error.message === 'string' &&
        httpError.error.code &&
        typeof httpError.error.code === 'string';
};

export enum ServerErrorCode {
    QUERY_YIELDS_ZERO_PAPERS = 'QUERY_YIELDS_ZERO_PAPERS',
}

export const toAlertData = (serverError: ServerError): Pick<AlertData, 'title' | 'body' | 'variant'> => {
    return {
        title: `Unknown Server error`,
        body: serverError.message,
        variant: AlertVariant.danger
    };
};
