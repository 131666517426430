import { Client, createClient } from 'react-fetching-library';
import { ActionExtended, AuthOptions } from '../Services/Api';

const authorizationHeader = 'Authorization';

const requestTokenInterceptor = (token: string) => (_client: Client) => async (action: ActionExtended) => {
    const headers = action.headers ? { ...action.headers } : {};

    if (!action.config) {
        throw new Error('Invalid action without config. All actions require config.auth to be set');
    }

    if (action.config.auth === AuthOptions.REQUIRED && token === '') {
        throw new Error('Invalid call without a token');
    }

    if (action.config.auth === AuthOptions.REQUIRED || action.config.auth === AuthOptions.OPTIONAL) {
        headers[authorizationHeader] = `token ${token}`;
    }

    return {
        ...action,
        headers
    };
};

export function createClientWithData(token: string) {
    const interceptors = [
        requestTokenInterceptor(token)
    ];

    return createClient({
        requestInterceptors: interceptors
    });
}
