import * as React from 'react';
import {
    Button,
    Form,
    ActionGroup,
    Bullseye,
    LoginPage,
    Stack,
    StackItem
} from '@patternfly/react-core';
import { useHistory } from 'react-router-dom';
import { EnterEmail } from '../Components/Authentication/EnterEmail';
import { ConfirmPassword } from '../Components/Authentication/ConfirmPassword';
import { PubmedNavBar } from '../Components/Navbar/PubmedNavBar';
import { PubmedFooterBar } from '../Components/Navbar/PubmedFooterBar';
import { Mixpanel } from '../Components/Mixpanel/MixpanelWrapper';

import { useSignInQuery } from '../Services/UseSignInQuery';
import AuthContext from '../App/AuthContext';
import { style } from 'typestyle';
const bullseye_error = style({
    height: 25,
    color: 'red'
});

const bullseye_good = style({
    height: 25,
    color: 'green'
});

export const Login: React.FunctionComponent = (_props) => {
    const [ bullseyeStyle, setBullseyeStyle ] = React.useState<string>('');
    const [ message, setMessage ] = React.useState<string>('');
    const [ authEmail, setAuthEmail ] = React.useState<string>('');
    const [ authPassword, setAuthPassword ] = React.useState<string>('');
    const [ isValidEmail, setIsValidEmail ] = React.useState<boolean|undefined>(undefined);
    const [ isValidPassword, setIsValidPassword ] = React.useState<boolean|undefined>(undefined);
    const { setToken: setAuthToken } = React.useContext(AuthContext);

    const history = useHistory();
    const signinMutation = useSignInQuery();

    const onAuth = React.useCallback(() =>  {
        const signinParams = {
            access: 'login',
            email: authEmail,
            password: authPassword
        };
        setMessage('');
        signinMutation.mutate(signinParams).then((response) => {
            if (!response.error) {
                setBullseyeStyle(bullseye_good);
                if (response.payload.id) {
                    Mixpanel.regist({
                        id: response.payload.id,
                        email: authEmail
                    });
                }

                setAuthToken(response.payload?.token);
                history.push('/');
            } else {
                setBullseyeStyle(bullseye_error);
            }

            setMessage(response.payload?.message);
            if (response.payload?.message === undefined)
            {setMessage('An error has occurred, please try later');}
        });
    }, [ authEmail, authPassword, signinMutation, setAuthToken, history ]);

    const images = {
        lg: '/bg_1200.jpg',
        sm: '/bg_768.jpg',
        xs: '/bg_576.jpg',
        sm2x: '/bg_768.jpg',
        xs2x: '/bg_576.jpg'
    };

    return (
        <>
            <PubmedNavBar />
            <LoginPage
                backgroundImgSrc={ images }
                loginTitle= { 'Log In' }
                loginSubtitle={ 'Enter your email and password to gain access' }
                forgotCredentials={ <React.Fragment>
                    <Stack>
                        <StackItem className="pf-u-text-align-center" >
                  Don&apos;t have an Account? please<Button variant="link" component="a" href="/register">Register</Button>
                        </StackItem>
                        <StackItem/>
                        <StackItem className="pf-u-text-align-center" >
                            <Button variant="link" component="a" href="/forgot"> Forgot your password? </Button>
                        </StackItem>
                    </Stack>
                </React.Fragment> }
            >
                <Form>
                    <EnterEmail
                        emailLabel = { 'Email' }
                        email = { authEmail }
                        setEmail = { setAuthEmail }
                        validEmail = { isValidEmail }
                        setValidEmail = { setIsValidEmail }
                    />
                    <ConfirmPassword
                        passwordText1 = { 'Password' }
                        passwordText2 = { 'Re-enter password' }
                        password = { authPassword }
                        setPassword = { setAuthPassword }
                        setValidPassword = { setIsValidPassword }
                        double = { false }
                    />
                    <ActionGroup>
                        <Button variant="primary" isBlock isDisabled={ !isValidPassword || !isValidEmail } onClick={ onAuth }>Log In</Button>
                    </ActionGroup>
                    <Bullseye className={ bullseyeStyle }>
                        { message }
                    </Bullseye>
                </Form>
            </LoginPage>
            <PubmedFooterBar />
        </>
    );
};
