import * as React from 'react';
import { AlertVariant } from '@patternfly/react-core';
import { HttpError, isServerError, toAlertData } from '../../Utils/HttpError';
import { AlertData } from '../../Components/AlertList';

type UseAlertsProcessErrors = (httpError: HttpError, functionName: string) => boolean;

export const useAlerts = (errorCallback: UseAlertsProcessErrors) => {

    const [ errorList, setErrorList ] = React.useState<AlertData[]>([]);

    React.useEffect(() => {
        let timeoutId: NodeJS.Timeout;
        const cleanOldAlerts = () => {
            setErrorList(prev => {
                const newList = [ ...prev.filter(alert => Date.now() - alert.key <= 3000) ];
                if (newList.length === prev.length) {
                    return prev;
                }

                return newList;
            });
            timeoutId = setTimeout(cleanOldAlerts, 800);
        };

        if (errorList.length > 0) {
            cleanOldAlerts();
        }

        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [ setErrorList, errorList ]);

    const addError = React.useCallback((title: string, message?: string, variant?: AlertVariant) => {
        setErrorList(prev => [ ...prev, {
            title,
            body: message,
            variant,
            key: Date.now()
        }]);
    }, [ setErrorList ]);

    const processError = React.useCallback((httpError: HttpError, functionName: string) => {
        // Attempt to see if is an error we know
        if (isServerError(httpError)) {
            if (errorCallback(httpError, functionName)) {

            } else {
                const alertData = toAlertData(httpError.error);
                addError(
                    alertData.title,
                    alertData.body,
                    alertData.variant
                );
            }
        } else {
            if (httpError.status_code === 400) {
                addError(
                    functionName,
                    httpError.error.message,
                    AlertVariant.warning
                );
            } else if (httpError.status_code !== undefined && httpError.status_code !== 200) {
                addError(
                    'Connection error',
                    'An error occurred when making the request' +
                    '<ul>' +
                    `<li><strong>Request</strong>: ${ functionName }</li>` +
                    `<li><strong>Error code</strong>: ${ httpError.status_code }</li>` +
                    '</ul>',
                    AlertVariant.danger
                );
            }
        }
    }, [ addError, errorCallback ]);

    const removeAlert = React.useCallback((key: number) => {
        setErrorList([ ...errorList.filter((alert) => alert.key !== key) ]);
    }, [ errorList ]);

    return {
        errorList,
        addError,
        processError,
        removeAlert
    };
};
