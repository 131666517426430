import * as React from 'react';
import { SearchIcon } from '@patternfly/react-icons';
import { Button, ButtonVariant, Form, Spinner, Split, SplitItem, Stack, StackItem } from '@patternfly/react-core';
import { PapersQuery, PapersQueryBase, PapersQueryProps } from './SearchForm/PapersQuery';
import { style } from 'typestyle';
import { SearchKeywords } from './SearchForm/SearchKeywords';
import { SearchElement } from '../Types/Search';
import { SelectedSearchElement } from '../Pages/Hooks/useSearchElementStash';
import { SourceSelector } from './SourceSelector/SourceSelector';

interface SearchFormProps {
    papersQuery: PapersQueryProps;
    addSearchElement: (element: SearchElement) => void;
    removeSearchElement: (element: SearchElement) => void;
    searchElements: Array<SelectedSearchElement>;
    onAccess: () => boolean;
    scheduleQuery: () => void;
    addNewSearch?: (searchTerm: string, query: PapersQueryBase) => void;
    downloadedCount?: number;
    loading: boolean;
    access: boolean;
    loginButtonText: string;
    loginButtonClass: string;
    isEditablePubmedQuery?: boolean;
    preloadedQueries?: ReadonlyArray<PapersQueryBase>;
}

export const popoverIconClassName = style({
    cursor: 'pointer'
});

const marginBottomClassName = style({
    marginBottom: 5
});

export const SearchForm: React.FunctionComponent<SearchFormProps> = (props) => {
    const runButtonIsDisabled = props.loading || !props.papersQuery.count;

    const buttonSplitClassname = style({
        alignSelf: 'flex-end'
    });

    return (
        <>
            <Form isHorizontal id='search-query-form'>
                <Stack>
                    <StackItem>
                        <Split hasGutter>
                            <SplitItem isFilled>
                                <PapersQuery
                                    { ...props.papersQuery }
                                    scheduleQuery={ props.scheduleQuery }
                                    addNewSearch={ props.addNewSearch }
                                    preloadedQueries={ props.preloadedQueries }
                                    isEditablePubmedQuery={ props.isEditablePubmedQuery }
                                />
                            </SplitItem>
                            <SplitItem>
                                <SourceSelector />
                            </SplitItem>
                        </Split>
                    </StackItem>
                    <StackItem className={ marginBottomClassName }>
                        <Split hasGutter>
                            <SplitItem isFilled>
                                <SearchKeywords
                                    searchElements={ props.searchElements }
                                    query={ props.papersQuery.query }
                                    addKeyword={ props.addSearchElement }
                                    removeKeyword={ props.removeSearchElement }
                                />
                            </SplitItem>
                            <SplitItem className={ buttonSplitClassname }>
                                <Button
                                    variant={ ButtonVariant.primary }
                                    isDisabled={ runButtonIsDisabled || props.loading }
                                    onClick={ props.scheduleQuery }
                                >
                                    Analyze&nbsp;&nbsp;
                                    { props.loading ? (
                                    <Spinner style={ {
                                        '--pf-c-spinner--Color': '#e41a1c',
                                        '--pf-c-spinner--stroke-width-multiplier': '0.25',
                                         '--pf-c-spinner--diameter': '1rem',
                                        '--pf-c-spinner__lead-ball--after--BackgroundColor': '#d73027',
                                        '--pf-c-spinner__tail-ball--after--BackgroundColor':'#ff7f00',
                                        '--pf-c-spinner--AnimationDuration': '1.7s'
                                    } as any }  size="sm" />
                                    ) : <SearchIcon /> }
                                </Button>
                            </SplitItem>
                        </Split>
                    </StackItem>
                </Stack>
            </Form>
        </>
    );
};
