import { useCallback, useMemo, useState } from 'react';
import { SearchElement } from '../../Types/Search';

export type SelectedSearchElement = SearchElement & {
    color: number;
}

const findIndexInStash = (searchedElement: SearchElement, stash: Array<SearchElement>) => {
    return stash.findIndex((element) => element.id === searchedElement.id && element.type === searchedElement.type);
};

const findNextColor = (stash: Array<SelectedSearchElement>) => {
    const colors = stash.map(e => e.color).sort((a, b) => a - b);
    let nextColor = 0;
    for (const color of colors) {
        if (color > nextColor) {
            return nextColor;
        }

        nextColor = color + 1;
    }

    return nextColor;
};

const sanitize = <T extends SearchElement>(element: T): T => ({
    ...element,
    id: element.id.trim(),
    displayName: element.displayName.trim()
});

export const useSearchElementStash = (initialElements?: Array<SelectedSearchElement>) => {

    const  [ stash, setStash ] = useState<Array<SelectedSearchElement>>(() => initialElements?.map(sanitize) ?? []);

    const findIndex = useCallback((element: SearchElement) => {
        element = sanitize(element);
        return findIndexInStash(element, stash);
    }, [ stash ]);

    const add = useCallback((element: SearchElement) => {
        element = sanitize(element);
        setStash((prev) => {
            if (findIndexInStash(element, prev) === -1) {
                return [
                    ...prev,
                    {
                        ...element,
                        color: findNextColor(prev)
                    }
                ];
            }

            return prev;
        });
    }, [ setStash ]);

    const remove = useCallback((element: SearchElement) => {
        element = sanitize(element);
        setStash(prev => {
            const index = findIndexInStash(element, prev);
            if (index === -1) {
                return prev;
            }

            const newStash = [ ...prev ];
            newStash.splice(index, 1);

            const minColor = Math.min(...newStash.map(value => value.color));
            if (Math.floor(minColor / 7) >= 1) {
                const colorPadding = Math.floor(minColor / 7) * 7;
                return newStash.map(value => ({
                    ...value,
                    color: value.color -= colorPadding
                }));
            }

            return newStash;
        });
    }, [ setStash ]);

    const set = useCallback((elements: Array<SearchElement>) => {
        elements = elements.map(sanitize);
        setStash(prev => {
            if (prev.length !== elements.length ||
                !elements.every(value => prev.findIndex(value1 => value.id === value1.id && value.type === value1.type) !== -1)) {
                return elements.map((value, index) => ({ color: index, ...value }));
            } else {
                return prev;
            }
        });
    }, [ setStash ]);

    return useMemo(() => ({
        findIndex,
        add,
        remove,
        elements: stash,
        set
    }), [ findIndex, add, remove, stash, set ]);
};

export type UseSearchElementStashType = ReturnType<typeof useSearchElementStash>;
