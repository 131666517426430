import React from 'react';

export type Source = string;

interface SourceContext {
    selected: ReadonlyArray<Source>;
    available: ReadonlyArray<Source>;
    select: (source: Source) => void;
    unselect: (source: Source) => void;
    setAvailable: (sources: ReadonlyArray<Source>) => void;
}

const errFunction = () => {
    throw new Error('Context is not initialized');
};

export const SourceContext = React.createContext<SourceContext>({
    select: errFunction,
    unselect: errFunction,
    setAvailable: errFunction,
    selected: [],
    available: []
});

export const useSourceContext = () => React.useContext(SourceContext);

export const SourceContextProvider: React.FunctionComponent = props => {
    const [ selected, setSelected ] = React.useState<ReadonlyArray<Source>>([]);
    const [ available, setAvailableInternal ] = React.useState<ReadonlyArray<Source>>([]);
    const select = React.useCallback((source: Source) => {
        setSelected(prev => {
            if (prev.includes(source)) {
                return prev;
            }

            return [ ...prev, source ];
        });
    }, []);
    const unselect = React.useCallback((source: Source) => {
        setSelected(prev => {
            return prev.filter(s => s !== source);
        });
    }, []);

    const setAvailable = React.useCallback((available: ReadonlyArray<Source>) => {
        setAvailableInternal(available);
        setSelected(prev => prev.filter(p => available.includes(p)));
    }, []);

    const value = React.useMemo<SourceContext>(() => ({
        selected,
        available,
        select,
        unselect,
        setAvailable
    }), [ select, available, selected, unselect, setAvailable ]);

    return (
        <SourceContext.Provider value={ value }>
            { props.children }
        </SourceContext.Provider>
    );
};
