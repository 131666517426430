import * as React from 'react';
import { Modal, ModalProps, Button, ButtonVariant } from '@patternfly/react-core';
import { useHistory } from 'react-router-dom';

type ViewerProps = ModalProps &{
    linkTitle: string;
    component?: any;
}

export const NavLinkModal: React.FunctionComponent<ViewerProps> = (props) => {
    const { isOpen } = props;
    const [ isModalOpen, setIsModalOpen ] = React.useState<boolean>(false);

    const Component = props.component || Button;

    const history = useHistory();

    const handleModalToggle = React.useCallback(() => {
        setIsModalOpen(prevIsModalOpen => !prevIsModalOpen);
    }, [ setIsModalOpen ]);

    const closeModalByProps = React.useCallback(() => {
        handleModalToggle();

        setTimeout(() => {
            history.push('/');
        }, 500);
    }, [ handleModalToggle, history ]);

    React.useEffect(() =>{
        if (isOpen) {
            handleModalToggle();
        }
    }, [ handleModalToggle, isOpen ]);

    const modal =
        <Modal
            showClose={ props.showClose }
            disableFocusTrap={ props.disableFocusTrap }
            title={ props.title  }
            aria-describedby={ props['aria-describedby'] }
            isOpen={ isModalOpen }
            onClose={ (isOpen) ? closeModalByProps : handleModalToggle }
            variant="small"
            width={ props.width }
        >
            { props.children }
        </Modal>;

    const link =
        <Component
            onClick={ () => {
                handleModalToggle();
            } }
            variant={ ButtonVariant.link }
        >
            { props.linkTitle }
        </Component>;

    return (
        <React.Fragment>
            { modal }
            { link }
        </React.Fragment>
    );
};
