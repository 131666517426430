import { createContext } from 'react';

interface AuthContext {
    token: string;
    setToken: (token: string) => void;
}

const initialContext: AuthContext = {
    setToken: _token => {
        throw new Error('Unexpected use of AuthContext.SetToken');
    },
    token: ''
};

const AuthContext = createContext(initialContext);
export default AuthContext;
