import * as React from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts, { Chart, Legend, Point, SeriesBubbleOptions, PointMarkerOptionsObject } from 'highcharts';
import highChartsMoreFactory from 'highcharts/highcharts-more';
import highchartsCustomEventsFactory from 'highcharts-custom-events';
import { style } from 'typestyle';
import { Collapse } from 'react-collapse';
import { Button, ButtonVariant, Split, SplitItem } from '@patternfly/react-core';
import { ChevronCircleDownIcon, ChevronCircleRightIcon, OutlinedWindowMinimizeIcon } from '@patternfly/react-icons';
import { ColorPalette } from '../Utils/Color';
import highChartsBrokenAxis from 'highcharts/modules/broken-axis';

import { Resizable } from 're-resizable';
import { useWindowSize } from 'react-use';
import { SelectedSearchElement } from '../Pages/Hooks/useSearchElementStash';
import { PaperData } from '../Types/PaperData';
import { SearchElementType } from '../Types/Search';

highChartsBrokenAxis(Highcharts);
highChartsMoreFactory(Highcharts);
highchartsCustomEventsFactory(Highcharts as any);
///test
// from https://stackoverflow.com/questions/10599933/convert-long-number-into-abbreviated-string-in-javascript-with-a-special-shortn
const abbreviateNumber = (num: number): string => {
    if (num === null) {
        return '';
    }

    if (num === 0) {
        return '0';
    }

    const b = (num).toPrecision(2).split('e'); // get power
    const k = b.length === 1 ? 0 : Math.floor(Math.min(+(b[1].slice(1)), 14) / 3); // floor at decimals, ceiling at trillions
    const c = k < 1 ? num.toFixed(0) : (num / Math.pow(10, k * 3)).toFixed(1); // divide by power
    const d = +c < 0 ? c : Math.abs(+c); // enforce -0 is 0
    const e = d + [ '', 'K', 'M', 'B', 'T' ][k]; // append power
    return e;
};

const colorForKeyword = (label: string, elements: Array<SelectedSearchElement>) => {
    const element = elements.find(value =>  value.id === label);
    return element?.color ?? 0;
};

const nameForKeyword = (name: string, elements: Array<SelectedSearchElement>) => {
    const element = elements.find(value =>  value.id === name);
    return element?.displayName ?? name;
};

export interface ScicartaPoint extends Point {
    custom: any;
}

interface GraphProps {
    message: string;
    data?: any;
    layout?: any;
    selectedPapers?: ReadonlyArray<PaperData>;
    loading: boolean;
    onHover: (data: ScicartaPoint) => void;
    onClick: (data: ScicartaPoint) => void;
    onUpdate: (data: Readonly<any>) => void;
    selectedSearchElements: Array<SelectedSearchElement>;
    contentWidth: number;
    contentHeight: number;
    heightPlotBase: number;
    onResizePlot: (delta: number) => void;
}

export type GraphData = any & {
    customdata?: PaperData[];
    opacity?: number;
}

const plotClassNameBase = style({
    width: '100%',
    borderTop: 'none',
    overflow: 'hidden'
});

const collapseActionContentClassName = style({
    marginTop: '15px',
    zIndex: 10,
    position: 'relative'
});

const collapseButtonClassName = style({
    textAlign: 'left'
});

const impactNotFoundLabel = style({
    textAlign: 'center'
});

const selectedClassName = style({
    stroke: 'black',
    strokeWidth: 2,
    strokeOpacity: 1
});

const unselectedClassName = style({
    stroke: 'none'
});

const minHeightPlot = 100;

const yToTooltip: Record<number, string> = {
    0.2: 'No Trial',
    0.3: 'Non Clinical Poster',
    0.4: 'Unknown',
    0.5: 'Non Clinical Oral',
    0.6: 'Phase 1',
    0.7: 'Clinical Posters',
    0.8: 'Phase 2',
    0.9: 'Clinical Oral or Plenary',
    1.0: 'Phase 3'
};

const processData = (
    elementsRaw: any | undefined,
    selectedPaper: ReadonlyArray<PaperData>,
    selectedSearchElements: Array<SelectedSearchElement>,
    layout: any
): Highcharts.Options & {
    legend: {
        itemEvents: any;
    };
} => {
    const series: Array<SeriesBubbleOptions> = [];
    let maxCitations = 0;
    let minCitations = 0;

    const elementsForLegendOrder = selectedSearchElements
    .filter(value => value.type === SearchElementType.STRING)
    .concat(selectedSearchElements.filter(value => value.type === SearchElementType.ENTITY));

    if (elementsRaw) {
        for (const element of elementsRaw) {
            if (element && element.marker !== undefined) {
                const x = element.x as Array<string>;
                const y = element.y as Array<number>;
                const size = element.marker.size as Array<number>;
                const citation_count = element.citation_count as Array<string>;
                const customdata = element.customdata as Array<any>;
                const displayName = nameForKeyword(element.name, selectedSearchElements);
                const shape = element.marker.shape;

                let minSize = 8;
                let maxSize: any = '20%';

                const minZ = Math.min(...element.marker.size);
                const maxZ = Math.max(...element.marker.size);

                if (minZ === maxZ) {
                    minSize = 15;
                    maxSize = 15;
                }

                const serie: SeriesBubbleOptions = {
                    minSize,
                    maxSize,
                    data: x.map((_, index) => {

                        maxCitations = Math.max(maxCitations, customdata[index].citation);
                        minCitations = Math.min(minCitations, customdata[index].citation);

                        const isSelected = selectedPaper.findIndex(p => p.pmid === customdata[index].pmid) !== -1;

                        let tooltipContent;
                        if (customdata[index].pmid.startsWith('NCT')) {
                            tooltipContent = `${displayName} | Phase ${customdata[index].phase} trial`;
                        } else if (customdata[index].pmid.includes('.')){
                            //tooltipContent = `${displayName} | Downloads ${Math.round(y[index] * 100)}`;
                            tooltipContent = `${displayName} | Downloads ${Math.round(customdata[index].relevance)}`;
                        }
                        else {
                            //tooltipContent = `${displayName} | ${Math.round(y[index] * 100)} normalized impact | ${citation_count[index]} citations paper`;
                            tooltipContent = `${yToTooltip[y[index]]}`;
                        }

                        let marker: PointMarkerOptionsObject | undefined = undefined;

                        if (customdata[index].pmid.startsWith('NCT')) {
                            marker = { symbol: 'diamond'};
                        } else if (customdata[index].pmid.includes('.')) {
                            marker = { symbol: 'square'};
                        }
                        return {
                            x: new Date(x[index]).getTime(),
                            y: y[index],
                            z: size[index] * 0.3,
                            marker,
                            className: isSelected ? selectedClassName : unselectedClassName,
                            custom: {
                                label: shape[index][0],
                                tooltip: {
                                    title: '',
                                    content: tooltipContent
                                },
                                data: customdata[index]
                            }
                        };
                    }),
                    color: ColorPalette.getColor(
                        colorForKeyword(
                            element.name, selectedSearchElements
                        )
                    ).accent.toStringColorWithAlpha(1.0),
                    opacity: 0.4,
                    type: 'bubble',
                    states: {
                        inactive: {
                            opacity: 1,
                            enabled: false
                        },
                        hover: {
                            enabled: false,
                            halo: {
                                size: 1
                            }
                        }
                    },
                    name: `${nameForKeyword(element.name, selectedSearchElements)} (${element.human_count})`,
                    events: {
                        legendItemClick(e: any) {
                            // Prevent the default (toggling legend) from happening, as we are controlling this elsewhere
                            e.preventDefault();
                        }
                    },
                    legendIndex: elementsForLegendOrder.findIndex(value => value.id === element.name)
                };

                series.push(serie);
            }
        }
    }

    // TODO bring these back to their original values
    const maxCitationSize = 5;
    const minCitationSize = 5;

    // With all dates
    //
    // const tickPositions = [
    //     ...((layout?.xaxis?.tick_positions ?? []).map((b: any) => new Date(b).getTime()))
    // ].sort().map(p => parseInt(p));

    // Only first and last dates
    let tickPositions = [
        ...((layout?.xaxis?.tick_positions ?? []).map((b: any) => new Date(b).getTime()))
    ].sort();
    if (tickPositions.length > 2) {
        tickPositions = [ tickPositions[0], tickPositions[tickPositions.length - 1] ];
    }

    return {
        chart: {
            type: 'bubble',
            shadow: false,
            zoomType: 'xy',
            panning: {
                enabled: true,
                type: 'xy'
            },
            panKey: 'shift',
            animation: false,
            // from https://jsfiddle.net/38d1f7vz/
            events: {
                render() {
                    // const distX = this.xAxis[0].toPixels(Date.parse('4/9/2021'), true);
                    const anyThis = this as any;
                    if (anyThis.customText) {
                        anyThis.customText.destroy();
                        anyThis.customText = undefined;
                    }

                    anyThis.customText = this.renderer.g('customText').add();

                    const breakTitles = layout?.xaxis?.axis_break_titles;
                    if (breakTitles) {
                        for (const [key, dateString] of Object.entries(breakTitles)) {
                            this.renderer.text(
                                key,
                                this.xAxis[0].toPixels(Date.parse(dateString as string), false),
                                this.yAxis[0].toPixels(1.2, true)
                            ).add(anyThis.customText);
                        }
                    }
                }
            }
        },
        xAxis: {
            minTickInterval: 24 * 3600 * 1000,
            min: new Date(layout?.xaxis?.min).getTime(), // Date.UTC(2021, 3, 9),
            max: new Date(layout?.xaxis?.max).getTime(), // Date.UTC(2022, 3, 13),
            labels: { formatter(x) {
                return Highcharts.dateFormat('%Y-%m', this.value as number);
            } },
            tickPositions,
            endOnTick: true,
            maxPadding: 0,
            minPadding: 0,
            breaks: Object.values(layout?.xaxis?.axis_break ?? []).map((b: any) => ({
                from: new Date(b.from).getTime(),
                to: new Date(b.to).getTime(),
                breakSize: b.breakSize
            })),
            plotBands: Object.values(layout?.xaxis?.axis_break ?? []).map((b: any) => ({
                from: new Date(b.from).getTime(),
                to: new Date(b.to).getTime(),
                color: '#EEEEEE',
            })),
            type: 'datetime'
        },
        yAxis: {
            min: 0, // Date.UTC(2021, 3, 9),
            max: 1.4, // Date.UTC(2022, 3, 13),

            plotLines: [
                {
                    value: 0,
                    width: 1,
                    color: '#ccd6eb'
                }
            ],
            lineWidth: 1,
            gridLineColor: 'transparent',
            tickInterval: 0.1,
            labels: {
                style:{fontSize:"9px"},
                useHTML: true,
                formatter() {
                    if (this.value === 1 ) {
                        return 'Phase 3';
                    } else if (this.value === 0.9) {
                        return 'Clinical Oral & Plenary';
                    } else if (this.value === 0.8) {
                        return 'Phase 2';
                    } else if (this.value === 0.7) {
                        return 'Clinical Posters';
                    } else if (this.value === 0.6) {
                        return 'Phase 1';
                    } else if (this.value === 0.5) {
                        return 'Non Clinical Oral';
                    } else if (this.value === 0.4) {
                        return 'Unknown Phase';
                    } else if (this.value === 0.3) {
                        return 'Non Clinical Poster';
                    } else if (this.value === 0.2) {
                        return 'No Trial';
                    }
                    // On the value after 0, show the "Impact not found" legend
                    const paddedTicks = (this.axis as any).paddedTicks as Array<number>;
                    const index = paddedTicks.indexOf(this.value as number);
                    if (index + 1 < paddedTicks.length && paddedTicks[index + 1] === 0) {
                        return `<div class="${ impactNotFoundLabel }"></br>Not Available</div>`;
                    }

                    if (index === paddedTicks.length - 2 && paddedTicks[paddedTicks.length - 1] < 1) {
                        return 'Highest';
                    }

                    return '';
                }
            }
        },
        legend: {
            itemEvents: {
                dblclick() {
                    if (this.hasData()) {
                        this.chart.series.filter((s: any) => s !== this).forEach((s: any) => s.hide());
                        this.show();
                    }
                },
                click() {
                    if (this.visible) {
                        const otherWithData = this.chart.series.filter((s: any) => s !== this).some((s: any) => s.visible  && s.hasData());
                        if (otherWithData) {
                            this.hide();
                        } else {
                            this.show();
                            // Workaround to prevent the bubbleLegend from being hidden:
                            const legend = this.chart.legend as Legend & { bubbleLegend: { visible: boolean }};
                            legend.update({ bubbleLegend: { enabled: true }});
                            legend.bubbleLegend.visible = true;
                        }
                    } else {
                        this.show();
                    }
                }
            },
            layout: 'vertical',
            align: 'right',
            verticalAlign: 'top',
            // title: { text: series.length > 0 ?
            //     '<span style="font-size: 10px; color: #000"> ▣ bioR<i>x</i>iv  &nbsp &nbsp  </span> <br/> <br/>\
            //      <span style="font-size: 10px; color: #000">♢ Clinical trials</span> <br/> \
            //      <span style="font-size: 10px; color: #000; font-weight: normal">○ starting  &nbsp &nbsp</span>    \
            //      <span style="font-size: 10px; color: #000; font-weight: normal">→ ongoing  &nbsp &nbsp</span>    \
            //      <span style="font-size: 10px; color: #000; font-weight: normal">﹖ unknown </span>  <br/>  \
            //      <span style="font-size: 10px; color: #000; font-weight: normal">x aborted  &nbsp &nbsp</span>    \
            //      <span style="font-size: 10px; color: #000; font-weight: normal">✓ completed  </span>  \
            //      <span style="font-size: 10px; color: #000; font-weight: normal">★ approved</span> <br/> <br/>  \
            //      <span style="font-size: 10px; color: #000">◯ Pubmed</span>' : ''
            // },
            itemMarginTop: 4,
            bubbleLegend: {
                enabled: false,
                borderColor: '#000000',
                color: '#ffffff',
                maxSize: maxCitationSize,
                labels: {
                    formatter() {
                        // if (this.value === maxCitationSize) {
                        //     return `Most citations (${abbreviateNumber(maxCitations)})`;
                        // }

                        return `Least citations (${abbreviateNumber(minCitations)})`;
                    }
                },
                ranges: [
                    {
                        value: minCitationSize
                    },
                    {
                        value: maxCitationSize
                    }
                ]
            }
        },
        plotOptions: {
            bubble: {
                tooltip: {
                    headerFormat: '',
                    pointFormat: '{point.custom.tooltip.content}'
                }
            },
            series: {
                animation: false,
                dataLabels: {
                    animation: false,
                    enabled: true,
                    inside: true,
                    style: {
                        fontSize: '10px',
                        textOutline: '1px',
                        color: 'black',
                    },
                    formatter() {
                        return (this.point as any).custom.label;
                    }
                }
            }

        },
        tooltip: {
            shared: true,
            backgroundColor: '#ddd',
            followPointer: false,
            shadow: false,
            animation: false,
            shape: 'square'
        },


        // Rules to define how the graph should look in Mobiles or small windows
        responsive: {
            rules: [{
                condition: {
                    maxWidth: 500
                },
                chartOptions: {
                    legend: {
                        align: 'center',
                        verticalAlign: 'bottom',
                        layout: 'horizontal'
                    },
                    yAxis: {
                        labels: {
                            align: 'left',
                            x: 0,
                            y: -5
                        },
                        title: {
                            text: undefined
                        }
                    },
                    subtitle: {
                        text: undefined
                    },
                    credits: {
                        enabled: false
                    }
                }
            }]
        },
        series
    };
};

export const Graph: React.FunctionComponent<GraphProps> = (props) => {
    const resizableAnchorSize = 10;
    const heightClassName = style({
        height: props.contentHeight - resizableAnchorSize
    });

    const highchartsGraph = React.useRef<Chart>();

    const graphCreated = React.useCallback(graph => {
        if (graph) {
            highchartsGraph.current = graph;
        }
    }, []);

    React.useLayoutEffect(() => {
        const graph = highchartsGraph.current;
        if (graph) {
            graph.reflow();
        }
    }, [ highchartsGraph, props.contentWidth ]);

    const { height: windowHeight } = useWindowSize();

    const plotClassName = `${ (props.layout?.height !== undefined) ? heightClassName + ' ' : '' }${plotClassNameBase}`;
    const dataPlot = React.useMemo(() => {return processData(props.data, props.selectedPapers ?? [], props.selectedSearchElements, props.layout);}, [ props.data, props.selectedPapers, props.selectedSearchElements, props.layout ]);

    console.log(dataPlot);

    const postProcessDataPlot = React.useMemo(() => {
        const onHover = props.onHover;
        const onClick = props.onClick;

        return {
            ...dataPlot,
            chart: {
                ...dataPlot.chart,
                height: props.contentHeight
            },
            title: {
                text: ''
            },
            series: dataPlot.series?.map(s => ({
                ...s,
                point: {
                    events: {
                        click: onClick,
                        mouseOver: onHover
                    }
                }
            }))
        };
    }, [ props.contentHeight, dataPlot, props.onClick, props.onHover ]);

    const plot = () => {
        if (postProcessDataPlot !== undefined && props.layout) {
            return (
                <HighchartsReact
                    className={ plotClassName }
                    options={ postProcessDataPlot }
                    highcharts={ Highcharts }
                    callback={ graphCreated }
                />
            );
        } else {
            return (
                <HighchartsReact
                    className={ plotClassName }
                    options={ postProcessDataPlot }
                    highcharts={ Highcharts }
                    callback={ graphCreated }
                />
            );
        }
    };

    const [ isOpenCollapsePanel, setIsOpenCollapsePanel ] = React.useState<boolean>(true);
    const resizableClassNameBase = style({
        marginTop: '-1px',
        border: '1px solid #cccccc',
        width: '100%',
        height: props.contentHeight,
        overflow: 'hidden'
    });

    const anchorClassName = style({
        height: resizableAnchorSize,
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        position: 'absolute',
        left: '0',
        right: '0',
        bottom: '0'
    });
    const resizableAnchorMarker = style({
        textAlign: 'center',
        color: '#cccccc'
    });
    const resizableAnchorMarkerTop = style({
        marginTop: '-10px'
    });
    const resizableAnchorMarkerBottom = style({
        marginTop: '-15px'
    });

    return (
        <div id="PlotContainer">
            <div className={ collapseActionContentClassName }
            >
                <Button
                    isBlock={ true }
                    variant={ ButtonVariant.tertiary }
                    onClick={ () => {
                        setIsOpenCollapsePanel(!isOpenCollapsePanel);
                    } }
                >
                    <Split>
                        <SplitItem isFilled
                            className={ collapseButtonClassName }
                        >
                            { props.message }
                        </SplitItem>
                        <SplitItem>
                            { isOpenCollapsePanel ?  <ChevronCircleDownIcon /> : <ChevronCircleRightIcon /> }
                        </SplitItem>
                    </Split>
                </Button>
            </div>
            <Collapse isOpened={ isOpenCollapsePanel } >
                <Resizable
                    defaultSize={ {
                        width: '100%',
                        height: props.contentHeight
                    } }
                    minWidth="100%"
                    maxWidth="100%"
                    minHeight={ minHeightPlot }
                    maxHeight={ Math.max(props.heightPlotBase, windowHeight * 0.5) }
                    onResizeStop ={ (event, direction, refToElement, delta) => {
                        props.onResizePlot(delta.height);
                    } }
                    className={ resizableClassNameBase }
                >
                    <Split>
                        { /* Todo check why setting a width here helps to shrink */ }
                        <SplitItem className={ heightClassName } style={ { width: 10 } } isFilled>
                            { plot() }
                        </SplitItem>
                    </Split>
                    <div className={ anchorClassName }>
                        <div className={ `${resizableAnchorMarker} ${resizableAnchorMarkerTop}` } >
                            <OutlinedWindowMinimizeIcon />
                        </div>
                        <div className={ `${resizableAnchorMarker} ${resizableAnchorMarkerBottom}` } >
                            <OutlinedWindowMinimizeIcon />
                        </div>
                    </div>
                </Resizable>
            </Collapse>
        </div>
    );

};
