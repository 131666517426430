import * as React from 'react';
import {
    TextInput,
    FormGroup
} from '@patternfly/react-core';

interface ConfirmPasswordProps {
  passwordText1: string;
  passwordText2: string;
  password: string;
  setPassword: (password: string) => void;
  setValidPassword: (validPassword: boolean | undefined) => void;
  double: boolean;
}

export const ConfirmPassword: React.FunctionComponent<ConfirmPasswordProps> = (props) => {
    const [ isValidPassword1, setIsValidPassword1 ] = React.useState<boolean|undefined>(undefined);
    const [ isValidPassword2, setIsValidPassword2 ] = React.useState<boolean|undefined>(undefined);

    const validPassword1 = (password: string) => {
        const valid = password.length >= 8;
        setIsValidPassword1(valid);
        props.setPassword(password);
        if (props.double)
        {props.setValidPassword(isValidPassword2 && valid);}
        else
        {props.setValidPassword(valid);}
    };

    const validPassword2 = (password: string) => {
        const valid = password === props.password;
        setIsValidPassword2(valid);
        props.setValidPassword(isValidPassword1 && valid);
    };

    return (
        <>
            <FormGroup
                label={ props.passwordText1 }
                fieldId="password1"
                isRequired
                helperText = { ' ' }
                helperTextInvalid={ 'password must be lenght 8 caracters' }
                validated={ isValidPassword1 ? 'success' : 'error' }
            >
                <TextInput
                    type="password"
                    id="password1" isRequired
                    validated={ isValidPassword1 ? 'success' : 'error' }
                    onChange = { validPassword1 }
                />
            </FormGroup>
            { props.double ?
                <FormGroup
                    label={ props.passwordText2 }
                    fieldId="password2"
                    isRequired
                    helperText = { ' ' }
                    helperTextInvalid={ 'Passwords don\'t match' }
                    validated={ isValidPassword2 ? 'success' : 'error' }
                >
                    <TextInput
                        type="password"
                        id="password2"
                        isRequired
                        validated={ isValidPassword2 ? 'success' : 'error' }
                        onChange = { validPassword2 }
                    />
                </FormGroup>
                :
                ''
            }
        </>
    );
};
