import * as React from 'react';
import { useEffectOnce } from 'react-use';
import {
    useParams
} from 'react-router-dom';

import {
    Page,
    PageSection,
    TextContent,
    Button,
    Divider,
    Text,
    TextVariants,
    BackgroundImage
} from '@patternfly/react-core';

import { PubmedNavBar } from '../Components/Navbar/PubmedNavBar';
import { PubmedFooterBar } from '../Components/Navbar/PubmedFooterBar';

import { useVerifyMutation } from '../Services/UseVerifyMutation';

export const Verify: React.FunctionComponent = (_props) => {
    const [ message, setMessage ] = React.useState<string>('');
    const verification  = useVerifyMutation();
    const { token } = useParams();

    useEffectOnce(() => {
        if (token) {
            const verifyQuery = {
                token
            };
            verification.mutate(verifyQuery).then((response) => {
                if (!response.error) {
                    setMessage('Your email has been verified.');
                } else {
                    setMessage('Verification email link is no longer valid.');
                }
            });
        }
    });

    const images = {
        lg: '/bg_1200.jpg',
        sm: '/bg_768.jpg',
        xs: '/bg_576.jpg',
        sm2x: '/bg_768.jpg',
        xs2x: '/bg_576.jpg'
    };

    return (
        <>
            <PubmedNavBar />
            <BackgroundImage src={ images } />
            <Page className='page' >
                <PageSection variant="light">
                    <TextContent>
                        <Text className="pf-u-text-align-center"  component={ TextVariants.h1 }>
                            { message }
                            <Divider/>
                            <Button variant="link" component="a" href="/">Go to Scicarta</Button>
                        </Text>
                    </TextContent>
                </PageSection>
            </Page>
            <PubmedFooterBar />
        </>
    );
};
