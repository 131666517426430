import * as React from 'react';
import {
    Button,
    Form,
    ActionGroup,
    Bullseye,
    LoginPage
} from '@patternfly/react-core';

import { EnterEmail } from '../Components/Authentication/EnterEmail';
import { PubmedNavBar } from '../Components/Navbar/PubmedNavBar';
import { PubmedFooterBar } from '../Components/Navbar/PubmedFooterBar';

import { useSignInQuery } from '../Services/UseSignInQuery';

import { style } from 'typestyle';
const bullseye_error = style({
    height: 25,
    color: 'red'
});

const bullseye_good = style({
    height: 25,
    color: 'green'
});

export const ForgotPassword: React.FunctionComponent = (_props) => {
    const [ bullseyeStyle, setBullseyeStyle ] = React.useState<string>('');
    const [ message, setMessage ] = React.useState<string>('');
    const [ authEmail, setAuthEmail ] = React.useState<string>('');
    const [ isValidEmail, setIsValidEmail ] = React.useState<boolean|undefined>(undefined);

    const signinMutation = useSignInQuery();

    const onAuth = React.useCallback(() =>  {
        const signinParams = {
            access: 'forgot',
            email: authEmail,
            password: ''
        };
        setMessage('');
        signinMutation.mutate(signinParams).then((response) => {
            if (!response.error) {
                setBullseyeStyle(bullseye_good);
            } else {
                setBullseyeStyle(bullseye_error);
            }

            setMessage(response.payload?.message);
            if (response.payload?.message === undefined)
            {setMessage('An error has occurred, please try later');}
        });
    }, [ authEmail, signinMutation ]);

    const images = {
        lg: '/bg_1200.jpg',
        sm: '/bg_768.jpg',
        xs: '/bg_576.jpg',
        sm2x: '/bg_768.jpg',
        xs2x: '/bg_576.jpg'
    };

    return (
        <>
            <PubmedNavBar />
            <LoginPage
                backgroundImgSrc={ images }
                loginTitle= { 'Forgot password' }
                loginSubtitle={ 'Enter your email to send you a link to reset your password' }
            >
                <Form>
                    <EnterEmail
                        emailLabel = { 'Email' }
                        email = { authEmail }
                        setEmail = { setAuthEmail }
                        validEmail = { isValidEmail }
                        setValidEmail = { setIsValidEmail }
                    />
                    <ActionGroup>
                        <Button variant="primary" isBlock isDisabled={ !isValidEmail } onClick={ onAuth }>Send Link</Button>
                    </ActionGroup>
                    <Bullseye className={ bullseyeStyle }>
                        { message }
                    </Bullseye>
                    <Button variant="link" component="a" href="/">Go to Scicarta</Button>
                </Form>
            </LoginPage>
            <PubmedFooterBar />
        </>
    );
};
