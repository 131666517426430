import * as React from 'react';
import { ShareQuery } from './ShareQuery';
import { SelectedSearchElement } from '../../Pages/Hooks/useSearchElementStash';

interface ShareQueryContainerProps {
    pubmedQuery: string;
    searchElements: Array<SelectedSearchElement>;
}

export const ShareQueryContainer: React.FunctionComponent<ShareQueryContainerProps> = (props) => {

    const [ isOpen, setIsOpen ] = React.useState<boolean>(false);
    const [ isCopied, setIsCopied ] = React.useState<boolean>(false);

    const onOpen = React.useCallback(() => {
        setIsOpen(true);
        setIsCopied(false);
    }, [ setIsOpen ]);

    const onClose = React.useCallback(() => {
        setIsOpen(false);
    }, [ setIsOpen ]);

    const onCopy = React.useCallback(() => {
        setIsCopied(true);
    }, [ setIsCopied ]);

    return (
        <ShareQuery
            pubmedQuery={ props.pubmedQuery }
            searchElements={ props.searchElements }
            isOpen={ isOpen }
            isCopied={ isCopied }
            onClose={ onClose }
            onOpen={ onOpen }
            onCopy={ onCopy }
        />
    );
};
