import * as React from 'react';
import {
    TextInput,
    FormGroup
} from '@patternfly/react-core';

interface EnterEmailProps {
  emailLabel: string;
  email: string;
  setEmail: (password: string) => void;
  validEmail: boolean | undefined;
  setValidEmail: (validPassword: boolean | undefined) => void;
}

export const EnterEmail: React.FunctionComponent<EnterEmailProps> = (props) => {

    const validEmail = (email: string) => {
        // eslint-disable-next-line max-len
        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const valid = re.test(email);
        props.setEmail(email);
        props.setValidEmail(valid);
    };

    return (
        <>
            <FormGroup
                label={ props.emailLabel }
                fieldId="email"
                isRequired
                helperText = { ' ' }
                helperTextInvalid={ 'Incorrect email format' }
                validated={ props.validEmail ? 'success' : 'error' }
            >
                <TextInput type="email" id="email" isRequired validated={ props.validEmail ? 'success' : 'error' } onChange = { validEmail }/>
            </FormGroup>
        </>
    );
};
