import * as React from 'react';
import {
    useParams
} from 'react-router-dom';
import {
    Button,
    Form,
    ActionGroup,
    Bullseye,
    LoginPage
} from '@patternfly/react-core';

import { ConfirmPassword } from '../Components/Authentication/ConfirmPassword';
import { useResetPasswordMutation } from '../Services/UseResetPasswordMutation';

import { PubmedNavBar } from '../Components/Navbar/PubmedNavBar';
import { PubmedFooterBar } from '../Components/Navbar/PubmedFooterBar';

import { style } from 'typestyle';
const bullseye_error = style({
    height: 25,
    color: 'red'
});

const bullseye_good = style({
    height: 25,
    color: 'green'
});

export const ResetPassword: React.FunctionComponent = (_props) => {
    const [ message, setMessage ] = React.useState<string>('');
    const [ authPassword, setAuthPassword ] = React.useState<string>('');
    const [ isValidPassword, setIsValidPassword ] = React.useState<boolean|undefined>(undefined);
    const [ bullseyeStyle, setBullseyeStyle ] = React.useState<string>('');
    const { token } = useParams();

    const  useReset = useResetPasswordMutation();

    const onAuth = React.useCallback(() =>  {
        const resetParams = {
            token,
            password: authPassword
        };
        setMessage('');
        useReset.mutate(resetParams).then((response) => {
            if (!response.error) {
                setBullseyeStyle(bullseye_good);
            } else {
                setBullseyeStyle(bullseye_error);
            }

            setMessage(response.payload?.message);
            if (response.payload?.message === undefined)
            {setMessage('An error has occurred,please try later');}
        });
    }, [ token, authPassword, useReset ]);

    const images = {
        lg: '/bg_1200.jpg',
        sm: '/bg_768.jpg',
        xs: '/bg_576.jpg',
        sm2x: '/bg_768.jpg',
        xs2x: '/bg_576.jpg'
    };

    return (
        <>
            <PubmedNavBar />
            <LoginPage
                backgroundImgSrc={ images }
                loginTitle="Reset your password"
                loginSubtitle="Please enter your password correctly 2 times to reset it"
            >
                <Form>
                    <ConfirmPassword
                        passwordText1 = { 'Password' }
                        passwordText2 = { 'Re-enter password' }
                        password = { authPassword }
                        setPassword={ setAuthPassword }
                        setValidPassword= { setIsValidPassword }
                        double = { true }
                    />
                    <ActionGroup>
                        <Button variant="primary" isBlock isDisabled={ !isValidPassword } onClick={ onAuth }>Reset Password</Button>
                    </ActionGroup>
                    <Bullseye className={ bullseyeStyle }>
                        { message }
                    </Bullseye>
                    <Button variant="link" component="a" href="/">Go to Scicarta</Button>
                </Form>
            </LoginPage>
            <PubmedFooterBar />
        </>
    );
};
