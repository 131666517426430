import React from 'react';
import { ClientContextProvider } from 'react-fetching-library';
import { createClientWithData } from './Client';
import keycloak from './Keycloak';
import { ReactKeycloakProvider } from '@react-keycloak/web';


import './App.css';
import '@patternfly/react-core/dist/styles/base.css';
// import '@patternfly/react-styles/css/patternfly-addons.css';
import { Routes } from '../Router';

import AuthContext from './AuthContext';
import { SourceContextProvider } from '../Pages/Context/Source/SourceContext';
import { PrivateRoute } from './PrivateRoute';

const App: React.FunctionComponent = () => {
    const [ authToken, setAuthToken ] = React.useState<string>('');
    return (
        <ReactKeycloakProvider authClient={ keycloak } initOptions={{ onLoad: 'login-required'}}>
            <SourceContextProvider>
                <AuthContext.Provider value={ { token: authToken, setToken: setAuthToken } }>
                    <ClientContextProvider client={ createClientWithData(authToken) }>
                        <PrivateRoute>
                            <Routes/>
                        </PrivateRoute>
                    </ClientContextProvider>
                </AuthContext.Provider>
            </SourceContextProvider>
        </ReactKeycloakProvider>
    );
};

export default App;
