import * as React from 'react';
import {
    Modal,
    Button,
    LoginForm,
    Divider,
    Stack,
    StackItem,
    Split,
    SplitItem,
    TextInput,
    Bullseye
} from '@patternfly/react-core';

export interface ModalAuthProps {
  isModalOpen: boolean;
  setIsModalOpen: (isModalOpen: boolean) => void;
  authEmail: string;
  setAuthEmail: (authEmail: string) => void;
  authPassword: string;
  setAuthPassword: (authPassword: string) => void;
  setAuthAccess: (authAccess: string) => void;
  onAuth: () => void;
}

export const ModalAuth: React.FunctionComponent<ModalAuthProps> = (props) => {

    const [ isValidEmail, setIsValidEmail ] = React.useState<boolean | undefined>(undefined);
    const [ isValidPassword, setIsValidPassword ] = React.useState<boolean | undefined>(undefined);
    const [ authButtonText, setAuthButtonText ] = React.useState<string>('Log In');
    const [ authToggleText, setAuthToggleText ] = React.useState<string>('Register');
    const [ authAuxText, setAuthAuxText ] = React.useState<string>('Don\'t have an Account, please');
    const [ forgotPassword, setForgotPassword ] = React.useState<boolean>(false);
    const [ oldAuthButtonText, setOldAuthButtonText ] = React.useState<string>('');
    const handleModalToggle = () => {
        props.setIsModalOpen(!props.isModalOpen);
    };

    const validEmail = (email: string) => {
        const re =
// eslint-disable-next-line max-len
/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
;
        setIsValidEmail(re.test(email));
        props.setAuthEmail(email);
    };

    const validPassword = (password: string) => {
        setIsValidPassword(password.length >= 8);
        props.setAuthPassword(password);
    };

    const toggleForgotPassword = () => {
        setForgotPassword(!forgotPassword);
        if (!forgotPassword) {
            console.log(authButtonText, oldAuthButtonText);
            setOldAuthButtonText(authButtonText);
            setAuthButtonText('Forgot Password');
            props.setAuthAccess('forgot');
            console.log('forgot');
        } else {
            console.log(authButtonText, oldAuthButtonText);
            setAuthButtonText(oldAuthButtonText);
            switch (authToggleText) {
                case 'Log In':
                    props.setAuthAccess('login');
                    console.log('login');
                    break;
                case 'Register':
                    props.setAuthAccess('signin');
                    console.log('signin');
                    break;
            }
        }
    };

    const toggleLoginRegister = () =>  {
        if (authButtonText === 'Log In') {
            props.setAuthAccess('signin');
            setAuthButtonText('Register');
            setAuthToggleText('Log In');
            setAuthAuxText('Already have an Account?');
        } else {
            props.setAuthAccess('login');
            setAuthButtonText('Log In');
            setAuthToggleText('Register');
            setAuthAuxText('Don\'t have an Account? please');
        }

    };

    return (
        <React.Fragment>
            <Modal
                width={ '450px' }
                title= { authButtonText }
                isOpen={ props.isModalOpen }
                onClose={ () => { handleModalToggle(); props.setAuthPassword('');} }
            >
                { (forgotPassword) ?
                    <Stack>
                        <StackItem className="pf-u-text-align-left">
              Please enter your email to send you a password reset link
                        </StackItem>
                        <StackItem className="pf-u-text-align-center">
                            <TextInput
                                type="text"
                                value = { props.authEmail }
                                onChange={ validEmail }
                                isRequired
                                validated={ isValidEmail ? 'success' : 'error' }
                            />
                        </StackItem>
                        <StackItem/>
                        <Divider/>
                        <StackItem className="pf-u-text-align-center">
                            <Split>
                                <SplitItem className="pf-u-text-align-left">
                                    <Button  variant="secondary" isInline onClick={ toggleForgotPassword }>Go Back</Button>
                                </SplitItem>
                                <SplitItem isFilled/>
                                <SplitItem className="pf-u-text-align-right">
                                    <Button variant="primary" isInline isDisabled={ !isValidEmail } onClick={ props.onAuth }>Send Link</Button>
                                </SplitItem>
                            </Split>
                        </StackItem>
                        <StackItem> </StackItem>
                        <StackItem>
                            <Bullseye>
                                { false ? 'Link copied to clipboard' : ' ' }
                            </Bullseye>
                        </StackItem>
                    </Stack>
                    :
                    <>
                        <LoginForm
                            usernameLabel="Email"
                            usernameValue={ props.authEmail }
                            onChangeUsername={ validEmail }
                            isValidUsername={ isValidEmail }
                            passwordLabel="Password"
                            passwordValue={ props.authPassword }
                            onChangePassword={ validPassword }
                            isValidPassword={ isValidPassword }
                            onAbort={ handleModalToggle }
                            isLoginButtonDisabled = { !isValidEmail || !isValidPassword }
                            onLoginButtonClick={ props.onAuth }
                            loginButtonLabel={ authButtonText }
                        />
                        <Divider/>
                        <Stack>
                            <StackItem className="pf-u-text-align-center" >
                                { authAuxText } <Button variant="link" isInline onClick = { toggleLoginRegister } >{ authToggleText }</Button>
                            </StackItem>
                            <StackItem/>
                            <StackItem className="pf-u-text-align-center" >
                                <Button variant="link" isInline onClick = { toggleForgotPassword } >Forgot your password? </Button>
                            </StackItem>
                        </Stack>
                    </>
                }
            </Modal>
        </React.Fragment>
    );
};
