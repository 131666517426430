import * as React from 'react';
import { Route, Switch, Redirect } from 'react-router';

import { Main } from './Pages/Main';
import { BrowserRouter } from 'react-router-dom';
import { TermsOfUse } from './Pages/TermsOfUse';
import { PrivacyPolicy } from './Pages/PrivacyPolicy';
import { Login } from './Pages/Login';
import { Register } from './Pages/Register';
import { Verify } from './Pages/AuthVerify';
import { ResetPassword } from './Pages/ResetPassword';
import { ForgotPassword } from './Pages/ForgotPassword';

interface Path {
    key: string;
    path: string | string[];
    component: React.ComponentType;
}

const pathRoutes: Path[] = [
    {
        key: 'tos',
        path: '/terms-of-use',
        component: TermsOfUse
    },
    {
        key: 'privacy-policy',
        path: '/privacy-policy',
        component: PrivacyPolicy
    },
    {
        key: 'verify-token',
        path: '/verify/:token',
        component: Verify
    },
    {
        key: 'reset-token',
        path: '/reset/:token',
        component: ResetPassword
    },
    {
        key: 'forgot',
        path: '/forgot',
        component: ForgotPassword
    },
    {
        key: 'register',
        path: '/register',
        component: Register
    },
    {
        key: 'login',
        path: '/login',
        component: Login
    },
    {
        key: 'main',
        path: [ '/', '/:action' ],
        component: Main
    }

];

type RoutesProps = {};

export const Routes: React.FunctionComponent<RoutesProps> = () => {
    return (
        <BrowserRouter>
            <Switch>
                { pathRoutes.map(pathRoute => (
                    <Route key={ pathRoute.key } component={ pathRoute.component } path={ pathRoute.path } exact={ true }/>
                )) }
                <Redirect to="/"/>
            </Switch>
        </BrowserRouter>
    );
};
