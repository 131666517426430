import { useMutation } from 'react-fetching-library';
import { AuthOptions, createAction, urls } from './Api';

const papersCount = urls.papersCount;

type Params = {
    paperQuery: string;
    sources: string;
}

const createPaperAction = (params: Params) => {
    return createAction({
        method: 'POST',
        url: papersCount,
        data: { 'search-term': params.paperQuery, sources: params.sources },
        auth: AuthOptions.DISABLED
    });
};

export const usePapersMutation = () => {
    return useMutation(createPaperAction);
};
