import { useKeycloak } from '@react-keycloak/web';
import * as React from 'react';
import {Bullseye, Spinner} from "@patternfly/react-core";
import LogRocket from "logrocket";

export const PrivateRoute: React.FunctionComponent = props => {
    const { keycloak } = useKeycloak();
    const isLoggedIn = keycloak.authenticated;

    console.log('isLoggedIn', isLoggedIn);
    console.log('keycloak', keycloak);

    if (isLoggedIn) {
        keycloak.loadUserInfo().then(console.log);
        keycloak.loadUserProfile().then(userProfile => {
            if (userProfile.username) {
                if (process.env.NODE_ENV === 'production') {
                    LogRocket.identify(userProfile.username);
                }
            }

            console.log(userProfile);
        });
    }

    return <>
        { isLoggedIn ? props.children : <Bullseye><Spinner/></Bullseye> }
    </>;
};
