import * as React from 'react';
import { Entities, EntitiesMenu } from '../../Components/EntitiesMenu';
import { style } from 'typestyle';
import { calc } from 'csx';
import { UseSearchElementStashType } from '../Hooks/useSearchElementStash';

const entitiesMenuClass = style({
    position: 'sticky',
    overflowY: 'auto',
    float: 'left',
    height: calc('100vh - 75px'), // header and footer plus page padding
    top: 0,
    maxWidth: 250
});

export interface EntitiesSectionProps {
    entities: Entities;
    stash: UseSearchElementStashType;
}

export const EntitiesSection: React.FunctionComponent<EntitiesSectionProps> = props => {

    return props.entities.length > 0 ? (
        <div className={ entitiesMenuClass } >
            <EntitiesMenu
                entities={ props.entities }
                showEntities={ props.stash.elements }
                addEntity={ props.stash.add }
                removeEntity={ props.stash.remove }
            />
        </div>
    ) : <React.Fragment />;
};
