
export const pad = (number: number, digits: number): string => {
    return number.toString().padStart(digits, '0');
};

export class HumanTimeDiff {
    readonly seconds: number;
    readonly minutes: number;

    public static fromMillis(millis: number) {
        let minutes = Math.floor(millis / 60000);
        let seconds = parseInt(((millis % 60000) / 1000).toFixed(0));

        if (seconds === 60) {
            seconds = 0;
            minutes++;
        }

        return new HumanTimeDiff(seconds, minutes);
    }

    public constructor(seconds: number, minutes: number) {
        this.seconds = seconds;
        this.minutes = minutes;
    }

    public toString() {
        return `${pad(this.minutes, 2)}:${pad(this.seconds, 2)}`;
    }

}
