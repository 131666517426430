import * as React from 'react';
import { Text, TextContent } from '@patternfly/react-core';
import { Link } from 'react-router-dom';
import { OutlinedArrowAltCircleLeftIcon } from '@patternfly/react-icons';
import { style } from 'typestyle';

interface ViewerProps {
    contentClassName?: string;
    levelTitle?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5';
    showHomeLink?: boolean;
}

const imgClassName = style({
    display: 'block',
    maxWidth: '100%',
    marginLeft: 'auto',
    marginRight: 'auto'
});

export const InstructionsText: React.FunctionComponent<ViewerProps> = (props) => {
    const levelTitle = (props.levelTitle) ? props.levelTitle : 'h2';

    const backLink = (props.showHomeLink) ?
        <Link className="back-in-page" to="/">
            <Text component={ 'h2' }>
                <OutlinedArrowAltCircleLeftIcon /> Back
            </Text>
        </Link> :
        '';

    return (
        <TextContent className={ props.contentClassName }>
            { backLink }
            <ol>
                <li>
                    Click on PubMed query and pull down to covid-19.
                    This gets all the covid papers from PubMed.
                </li>
                <li>
                    Edit the Search Terms box if you want to search any more keywords separated by commas.
                    It finds from the covid-19 corpus the papers that mention the entered keywords.
                </li>
                <li>In the little video above you&apos;ll see that I add <em>pregnancy</em> as another
                    Search Term. It shows up as a new item in the legend.
                    I click on pregnancy to then see only those papers that relate to
                    pregnancy and covid-19.
                </li>
                <li>
                    Click on the legend to toggle visibility of specific keywords.
                </li>
                <li>
                    Zoom in by drawing a box around the dots with your mouse. This can be a little tricky
                    but is easy once you get it.
                </li>
                <li>
                    You can also change the PubMed Query term to create a different corpus.
                </li>
                <li>
                    Currently it will pull down <em>only the first 20k</em> matching papers it finds
                    from PubMed to your query term.
                </li>
                <li>
                    Note that you can also search for compound keywords, for example
                    &quot;neutralizing antibodies, vaccine, chloroquine, case fatality rate&quot;
                </li>
            </ol>

            <img className={ imgClassName }
                src="/covid_pregnancy_animiated_3.gif"
                alt="covid pregnancy animiated"
            />

            <Text component={ levelTitle }>What do the dots mean?</Text>
            <ol>
                <li>A dot represents a paper.</li>
                <li>
                    The color of the dot depends on the keywords found in the paper.
                    If more than 1 keyword is found the dot will be the combined color of the colors.
                </li>
            </ol>

            <Text component={ levelTitle }>What does the placement of the dot mean?</Text>
            <ol>
                <li>Y-axis is the normalized impact factor of the journal</li>
                <li>Size of dot relates to number of citations.</li>
                <li>
                    Of course most of the recent covid-19 papers are so new that
                    they haven&apos;t yet been cited.
                </li>
            </ol>

            <Text component={ levelTitle }>How do I read an abstract of a paper?</Text>
            <ol>
                <li>Hover over a dot and it will show up in the lower left pane.</li>
                <li>Click on the dot and it will be fixed in the lower right pane.</li>
            </ol>

            <Text component={ levelTitle }>How do you I get full text?</Text>
            <ol>
                <li>Click on the title of the papers in the lower panes to go to the journal sites.</li>
                <li>Most covid-19 papers are full-text.</li>
            </ol>

            <Text component={ levelTitle }>How can I share papers I find?</Text>
            <ol>
                <li>Click on a dot to add it to the Selected Publications List in the lower right pane.</li>
                <li>From there you can copy a single paper or all the papers.</li>
                <li>Paste in your email and send.</li>
            </ol>

            <Text component={ levelTitle }>When does it pull new papers?</Text>
            <ol>
                <li>Every day its database updates with the latest papers from PubMed.</li>
            </ol>

            <Text component={ levelTitle }>How do I analyze a plot?</Text>
            <ol>
                <li>This is an open-ended tool for you to interpret the plot with your expertise.</li>
                <li>
                    Interesting questions it can address may include:
                    <ul>
                        <li>what&apos;s the arc of research on a topic</li>
                        <li>
                            what are the earlypapers of note (e.g., in &apos;herd immunity&apos; you&apos;ll see a
                            1973 NEJM paper that shows it didn&apos;t work for the rubella outbreak)
                        </li>
                        <li>
                            what papers are notable on a topic (based on keyword /
                            impact factor of journal / number of citations)
                        </li>
                    </ul>
                </li>
            </ol>

            <Text component={ levelTitle }>How do I share a &lsquo;carta&rsquo;?</Text>
            <ol>
                <li>Click on the share button, copy the link and email it to anyone.</li>
                <li>The recipient will be able to view the main search and keywords.</li>
            </ol>

            <Text component={ levelTitle }>What searches can I do?</Text>
            <ol>
                <li>You can search any topic as you would in PubMed. Only the results from the first 20k papers will be shown.</li>
            </ol>

            <Text component={ levelTitle }>Does this contain data other than PubMed?</Text>
            <ol>
                <li>
                    Not yet. Email <a href="mailto:info@scicarta.com">info@scicarta.com</a>
                    to tell us what other datasets or features you&rsquo;d like</li>
            </ol>
        </TextContent>
    );
};
