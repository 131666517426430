import * as React from 'react';
import { Grid, GridItem, PageSection, Tab, Tabs } from '@patternfly/react-core';
import { PaperViewer } from '../../Components/PaperViewer';
import { style } from 'typestyle';
import { PapersList } from '../../Components/PapersList';
import { PaperData } from '../../Types/PaperData';
import { Mixpanel } from '../../Components/Mixpanel/MixpanelWrapper';
import { Dispatch } from 'react';
import { SetStateAction } from 'react';
import { SelectedSearchElement } from '../Hooks/useSearchElementStash';

const paperViewerSectionContainerClassName = style({
    height: '100%'
});

const paperViewerClassName = style({
    height: '100%',
    overflowY: 'scroll',
    overflowX: 'hidden'
});

const tapSectionClassName = style({
    minHeight: '200px',
    maxHeight: '100%',
    overflowY: 'scroll',
    overflowX: 'hidden',
    border: '1px solid #ccc',
    borderTop: 'none',
    paddingLeft: 1
});

export interface ViewerSectionProps {
    papers: ReadonlyArray<PaperData>;
    setPapers: Dispatch<SetStateAction<ReadonlyArray<PaperData>>>;
    leftPaper: PaperData;
    setLeftPaper: Dispatch<SetStateAction<PaperData>>;
    rightPaper: PaperData;
    activeTabKey: number;
    setActiveTabKey: Dispatch<SetStateAction<number>>;
    queryKeywords: ReadonlyArray<string>;
    searchStash: ReadonlyArray<SelectedSearchElement>;
    onSelectAllPapers: () => void;
}

export const ViewerSection: React.FunctionComponent<ViewerSectionProps> = props => {

    const { setLeftPaper, setPapers, setActiveTabKey } = props;

    const deletePaper = (list: ReadonlyArray<PaperData>, index: number) => {
        const result = Array.from(list);
        result.splice(index, 1);

        return result;
    };

    const deleteAll = () => {
        setPapers([]);
    };

    const reorderPapers = (list: ReadonlyArray<PaperData>, startIndex: number, endIndex: number) => {
        const result = Array.from(list);
        const [ removed ] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const onDragPapersEnd = React.useCallback((result: any) => {
        if (!result.destination) {
            return;
        }

        setPapers(
            reorderPapers(
                props.papers,
                result.source.index,
                result.destination.index
            )
        );
    }, [ setPapers, props.papers ]);

    const onClickedPapersInListEnd = React.useCallback((paper: PaperData) => {
        Mixpanel.track({
            name: 'Click paper in selected paper list',
            props: paper
        });

        setLeftPaper(paper);
    }, [ setLeftPaper ]);

    const onDeletePaperInList = React.useCallback((result: any) => {
        if (result.index === undefined) {
            return;
        }

        Mixpanel.track({
            name: 'Removed paper from the selected paper list',
            props: {
                paperPmid: props.papers[ result.index ].pmid,
                paperTitle: props.papers[ result.index ].title
            }
        });

        setPapers(
            deletePaper(props.papers, result.index)
        );
    }, [ props.papers, setPapers ]);

    const handleTabClick = React.useCallback((event, tabIndex) => {
        Mixpanel.track({
            name: 'Click on tab',
            props: {
                tabSelected: (tabIndex === 0) ? 'Selected papers' : 'Analysis'
            }
        });

        setActiveTabKey(tabIndex);
    }, [ setActiveTabKey ]);

    return (
        <PageSection className="context-area" variant="light" isFilled>
            <Grid hasGutter className={ paperViewerSectionContainerClassName }>
                <GridItem span={ 6 }
                    className={ `${ paperViewerClassName } paperViewer` }
                >
                    <PaperViewer
                        paper={ props.leftPaper }
                        searchElements={ props.searchStash }
                        queryKeywords={ props.queryKeywords }
                    />
                </GridItem>
                <GridItem span={ 6 }
                    className={ `${ paperViewerClassName } paperViewer` }
                    id="tabs-container">
                    <Tabs
                        isFilled
                        activeKey={ props.activeTabKey }
                        onSelect={ handleTabClick }
                        id="tab-analysis-section"
                    >
                        <Tab title="Last selected paper"
                            eventKey={ 1 }
                        >
                            <div className={ style({ height: '100%' }) } >
                                <div className={ `${tapSectionClassName} tapSection` }>
                                    <PaperViewer
                                        paper={ props.rightPaper }
                                        searchElements={ props.searchStash }
                                        queryKeywords={ props.queryKeywords }
                                    />
                                </div>
                            </div>
                        </Tab>
                        <Tab title={ <span>
                                                    Selected papers
                        </span> }
                        eventKey={ 0 }
                        >
                            <div className={ style({ height: '100%' }) } >
                                <div className={ `${tapSectionClassName} tapSection` }>
                                    <PapersList
                                        items={ props.papers }
                                        onDragEnd={ onDragPapersEnd }
                                        onClicked={ onClickedPapersInListEnd }
                                        onDelete={ onDeletePaperInList }
                                        onDeleteAll={ deleteAll }
                                        onSelectAll={ props.onSelectAllPapers }
                                    />
                                </div>
                            </div>
                        </Tab>
                    </Tabs>
                </GridItem>
            </Grid>
        </PageSection>
    );
};
