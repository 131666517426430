import * as React from 'react';
import { removeStopwords } from 'stopword';
import { highLightChunks, StyledChunks, StylesWords } from './HighLightReconciliation';

export const highlightText = (text: string, keywords: Array<StylesWords>, initialStyledChunks?: StyledChunks[]): string => {
    const chunks: Array<StyledChunks> = highLightChunks(text, keywords, initialStyledChunks);

    return chunks.map(chunk => {
        const { end, style, start } = chunk;
        const item = text.substr(start, end - start);
        if (style) {
            let result = [ item ];
            if (style.bold) {
                result = [ '<strong>', ...result, '</strong>' ];

            }

            if (style.color) {
                result = [ `<span style="background-color:${ style.color }">`, ...result, '</span>' ];
            }

            return result.join('');
        } else {
            return item;
        }
    }).join('');
};

export const cleanText = (text: string|undefined): string => {
    if (text === undefined) {
        return '';
    }

    const elements = text
    .replace(/\"/g, '')
    .replace(/\[.+?]/g, '')
    .replace(/[\])}[{(]/g, '')// clusters are removed
    .split(' ');

    const cleanData = Array.from(new Set(removeStopwords(elements)));
    return cleanData.join(' ');
};

interface HighLightTextProps {
    text: string;
    keywords?: Array<StylesWords>;
    styledChunks?: Array<StyledChunks>;
    component?: React.ElementType;
}

export const HighlightText: React.FunctionComponent<HighLightTextProps> = (props) => {
    const Component = props.component || 'span';
    const html = React.useMemo(() => {
        if (!props.text) {
            return props.text;
        }

        return highlightText(props.text, props.keywords ?? [], props.styledChunks ?? []);
    }, [ props.keywords, props.text, props.styledChunks ]);

    return <Component dangerouslySetInnerHTML={ { __html: html } }/>;
};
