import { useCallback, useState, useEffect } from 'react';

const ANONYMOUS_LIMIT = '1000';

//const NOT_LOGGED_IN_MESSAGE = `Currently only analyzing the most recent ${ANONYMOUS_LIMIT} papers and 500 trials. `;
const NOT_LOGGED_IN_MESSAGE = ``;


export const useGraphMessage = (authToken: string) => {

    const [ graphMessage, setGraphMessage ] = useState<string>();
    const [ graphDefaultMessage, setGraphDefaultMessage ] = useState<string>();

    const resetGraphMessage = useCallback(() => {
        setGraphMessage(graphDefaultMessage);
    }, [ setGraphMessage, graphDefaultMessage ]);

    const setGraphMessageFound0Papers = useCallback((query: string) => {
        setGraphMessage(`No papers found for ${query} in PubMed`);
    }, [ setGraphMessage ]);

    const setGraphMessageSuccess = useCallback((_missing_keywords: string[], _threshold_reached: boolean) => {
        // This used to do something, but no longer it does
        // Todo: Refactor away
    }, [ ]);

    useEffect(() => {
        if (authToken === '') {
            setGraphDefaultMessage(NOT_LOGGED_IN_MESSAGE);
            if (!graphMessage) {
                setGraphMessage(graphDefaultMessage);
            }
        } else {
            setGraphDefaultMessage(undefined);
        }
    }, [ authToken, setGraphDefaultMessage, setGraphMessage, graphDefaultMessage, graphMessage ]);

    return {
        graphMessage,
        resetGraphMessage,
        setGraphMessageFound0Papers,
        setGraphMessageSuccess
    };
};
