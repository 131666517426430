import * as React from 'react';
import { Text, TextContent } from '@patternfly/react-core';

interface ViewerProps {
    contentClassName?: string;
    levelTitle?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5';

}

export const QuickStartText: React.FunctionComponent<ViewerProps> = (props) => {
    const levelTitle = (props.levelTitle) ? props.levelTitle : 'h1';
    const styleObj = {fontSize: 15}
    return (
        <TextContent className={ props.contentClassName }>
            <Text component={ levelTitle }>Quickstart</Text>
            <ol>
                <li style={styleObj}>
                    <b>📄 Select publications</b>
                    <br></br>
                    - Enter keywords for the topic you want to research
                    <br></br>
                    - Directly type keywords in the "Publications Query" field
                </li>
                    <br></br>
                <li style={styleObj}>
                    <b>👁 Go over concepts and abstracts</b>
                    <br></br>
                    - Drill down on concept sidebar for insights
                    <br></br>
                    - Hover over plot bubbles to read publications
                    <br></br>
                </li>
                    <br></br>
                <li style={styleObj}>
                    <b>🔍 Refine Search</b>
                    <br></br>
                    Refine your publication search by selecting concepts or entering textual searches
                    <br></br>
                    - To select concepts on sidebar, drill-down and click interesting concepts
                    <br></br>
                    and/or
                    <br></br>
                    - To enter textual search, directly type keywords in  in the "Concept/Text Keywords" field
                    <br></br>
                    For example, <em>vaccine, neutralizing antibodies, remdesivir</em>.
                </li>
                    <br></br>
                <li style={styleObj}>
                    <b>⟲ Iterate</b>
                    <br></br>
                    - Change the publication query or concept/text subquery
                </li>
                    <br></br>
                <li style={styleObj}>
                    <b>⇨ Share</b>
                    <br></br>
                    - Double click on bubble so add publications you want to share to your publication list
                    <br></br>
                    - Press on the Share button to get a hard link that shares your analysis
                    <br></br>

                </li>
            </ol>
        </TextContent>
    );
};
