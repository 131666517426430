import * as React from 'react';
import { PageSection } from '@patternfly/react-core';
import { Graph, ScicartaPoint } from '../../Components/Graph';
import { PlotResponse } from '../../Types/Plot';
import { SelectedSearchElement } from '../Hooks/useSearchElementStash';
import { PaperData } from '../../Types/PaperData';
import { useSize } from 'react-use';

const heightPlotBase = 310;

interface GraphSectionProps {
    message?: string;
    isLoading: boolean;
    plot: PlotResponse;
    selectedSearchElements: Array<SelectedSearchElement>;
    onHover: (point: ScicartaPoint) => void;
    onClick: (point: ScicartaPoint) => void;
    onUpdate: (figure: Readonly<any>) => void;
    papers: ReadonlyArray<PaperData>;
}

export const GraphSection: React.FunctionComponent<GraphSectionProps> = props => {

    const [ graphHeight, setGraphHeight ] = React.useState<number>(heightPlotBase);

    const onResizePlot = React.useCallback((delta: number)=>{
        setGraphHeight(graphHeight + delta);
    }, [ graphHeight ]);

    const [
        sized,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        _size
    ] = useSize(
        ({ width }) => (
            <PageSection variant="light">
                <Graph
                    message={ props.message ?? '' }
                    data={ props.plot.data }
                    layout={ {
                        ...props.plot.layout,
                        autosize: true,
                        height: graphHeight
                    } }
                    selectedSearchElements={ props.selectedSearchElements }
                    loading={ props.isLoading }
                    onHover={ props.onHover }
                    onUpdate={ props.onUpdate }
                    onClick={ props.onClick }
                    selectedPapers={ props.papers }
                    contentWidth={ width }
                    contentHeight={ graphHeight }
                    onResizePlot={ onResizePlot }
                    heightPlotBase = { heightPlotBase }
                />
            </PageSection>
        )
    );

    return sized;
};
