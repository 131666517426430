import * as React from 'react';
import { Button, ButtonVariant } from '@patternfly/react-core';
import { style } from 'typestyle';

const linkClassName = style({
    padding: 0
});

export const ContactUsModal: React.FunctionComponent = (_props) => {
    const mailto = 'info@scicarta.com';
    const onclickButton = React.useCallback(() => {
        document.location.href = `mailto:${ mailto }`;
    }, [ mailto ]);
    return (
        <React.Fragment>
            Contact us
            at <Button variant={ ButtonVariant.link }
                className={ linkClassName }
                onClick={ onclickButton }
            >
                { mailto }
            </Button>
        </React.Fragment>
    );
};
