import { SearchElement } from '../../Types/Search';
import { useCallback, useMemo, useState } from 'react';
import {Source} from "../Context/Source/SourceContext";

type Status = 'loading' | 'ready';

interface SearchingContext {
    query: string;
    elements: ReadonlyArray<SearchElement>;
    sources: ReadonlyArray<Source>;
    status: Status;
}

interface SearchingContextOp {
    context: SearchingContext;
    shouldLoad: (query: string, elements: ReadonlyArray<SearchElement>, sources: ReadonlyArray<Source>) => boolean;
    finishLoad: (query: string, elements: ReadonlyArray<SearchElement>, sources: ReadonlyArray<Source>) => void;
}

const serialize = (elements: ReadonlyArray<SearchElement>) => [ ...elements ]
.sort((a: SearchElement, b: SearchElement) => a.id.localeCompare(b.id))
.map(e => `${e.id}:${e.type}`)
.join(',');

const serializeSources = (sources: ReadonlyArray<string>) => sources.join(',');

export const useSearchingContext = (): SearchingContextOp => {
    const [ context, setContext ] = useState<SearchingContext>(() => ({
        query: '',
        elements: [],
        sources: [],
        status: 'ready'
    }));

    const shouldLoad = useCallback((query: string, elements: ReadonlyArray<SearchElement>, sources: ReadonlyArray<Source>) => {
        let doLoad = false;
        if (context.status === 'ready') {
            doLoad = true;
        } else if (
            context.query !== query
            || serialize(context.elements) !== serialize(elements)
            || serializeSources(context.sources) !== serializeSources(sources)
        ) {
            doLoad = true;
        }

        if (doLoad) {
            setContext({
                query,
                elements,
                sources,
                status: 'loading'
            });
        }

        return doLoad;
    }, [ context, setContext ]);

    const finishLoad = useCallback((query: string, elements: ReadonlyArray<SearchElement>, sources: ReadonlyArray<Source>) => {
        setContext(prev => {
            if (
                prev.query === query
                && serialize(prev.elements) === serialize(elements)
                && serializeSources(prev.sources) === serializeSources(sources)
            ) {
                return {
                    ...prev,
                    status: 'ready'
                };
            }

            return prev;
        });
    }, [ setContext ]);

    return useMemo(() => ({
        context,
        shouldLoad,
        finishLoad
    }), [ context, shouldLoad, finishLoad ]);
};
