import * as React from 'react';
import Tags from '@yaireo/tagify/dist/react.tagify';

import { FormGroup } from '@patternfly/react-core';
import { useSourceContext } from '../../Pages/Context/Source/SourceContext';
import { useRef } from 'react';

export const SourceSelector: React.FunctionComponent = () => {
    const source = useSourceContext();
    const sourceRef = useRef<typeof source>(source);
    sourceRef.current = source;

    const id = 'source-selector';

    console.log('rendering with selected count', source.selected.length);
    return (
        <div
            id={ id }
        >
            <FormGroup
                fieldId={ id }
                label="In"
            >
                <Tags
                    name={ id }
                    aria-described-by={ id }
                    value={ source.selected }
                    settings={ {
                        userInput: false,
                        whitelist: source.available,
                        dropdown: {
                            classname: 'tags-look',
                            enabled: 0,
                            closeOnSelect: false
                        },
                        hooks: {
                            beforeRemoveTag: () => {
                                if (sourceRef.current.selected.length > 1) {
                                    return Promise.resolve();
                                }

                                return Promise.reject();
                            }
                        },
                        callbacks: {
                            add: (e: any) => {
                                sourceRef.current.select(e.detail.data.value);
                            },
                            remove: (e: any) => {
                                sourceRef.current.unselect(e.detail.data.value);
                            }
                        }
                    } }
                />
            </FormGroup>
        </div>
    );
};
