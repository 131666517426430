export enum SearchElementType {
    STRING = 'STRING',
    ENTITY = 'ENTITY'
}

export type SearchElement = {
    type: SearchElementType;
    id: string;
    displayName: string;
}
