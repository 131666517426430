import * as React from 'react';
import { Button, FormGroup, InputGroup, Popover, SelectOption } from '@patternfly/react-core';
import { QuestionCircleIcon, TimesCircleIcon } from '@patternfly/react-icons';
import Tags from '@yaireo/tagify/dist/react.tagify';

import { ShareQueryContainer } from '../ShareQuery/ShareQueryContainer';
import { popoverIconClassName } from '../SearchForm';
import { ColorPalette } from '../../Utils/Color';
import { style } from 'typestyle';
import '@yaireo/tagify/dist/tagify.css';
import { SearchElement, SearchElementType } from '../../Types/Search';
import { SelectedSearchElement } from '../../Pages/Hooks/useSearchElementStash';
import { useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-fetching-library';
import {createSuggestionsAction, shouldApplySuggestionQuery} from './PapersQuery';
import { useDebounce } from 'react-use';
import {useSourceContext} from "../../Pages/Context/Source/SourceContext";
import {urls} from "../../Services/Api";
import {useSearchingContext} from "../../Pages/Hooks/useSearchingContext";

interface SearchKeywordsProps {
    searchElements: Array<SelectedSearchElement>;
    addKeyword: (element: SearchElement) => void;
    removeKeyword: (element: SearchElement) => void;
    query: string;
}

const tagsInputGroupClassName = style({
    $nest: {
        '& .tags-input': {
            flexGrow: 1
        }
    }
});

const placeholderKeywords = 'italy, genome, ace2, pregnancy';
const id = 'show-query';

const infoKeywordsHelp = () => {
    return (
        <Popover
            bodyContent={ <div>
                ← Select a concept from in the left sidebar<br></br>
                or<br></br>
                - Enter keywords such as:&nbsp;
                <em>{ placeholderKeywords }.</em>
            </div> }
            aria-label="Popover with Link"
            closeBtnAriaLabel="Close Popover with Link"
            position="bottom"
        >
            <QuestionCircleIcon className={ popoverIconClassName } />
        </Popover>
    );
};

const template = function(this: any, value: any): any {
    return `<tag 
                title='${ value.displayName }' 
                contenteditable='false'
                spellcheck="false"
                class='tagify__tag ${value.class ? value.class : ''}'
                style="${value.color !== undefined ? '--tag-bg:' + ColorPalette.getColor(value.color).pastel.toString() + ';' : '' }" 
                ${this.getAttributes(value)}
            >
                <x title='remove tag' class='tagify__tag__removeBtn'></x>
                <div style="border-radius: ${value.type === 'ENTITY' ? '4px' : '16px'};">
                    <span class="tagify__tag-text">${ value.displayName }</span>
                </div>
            </tag>`;
};

const suggestionItemTemplate = function(this: any, tagData: any){
    return `
        <div ${this.getAttributes(tagData)}
            class='tagify__dropdown__item ${tagData.class ? tagData.class : ""}'
            tabindex="0"
            role="option">
            <strong class="tagify-dropdown-name">${tagData.value}</strong>
            ${ tagData.count > 0 ? `<br/> <span class="tagify-dropdown-count" style="color: gray">${tagData.count}</span>` : '' }
        </div>
    `;
}

export const SearchKeywords: React.FunctionComponent<SearchKeywordsProps> = (props) => {

    // We shouldn't add this kind of queries next to the component level. But I'm considering this code as a playground
    // or PoC. With that said, lets go!
    // We already have a similar code in PapersQuery

    const [ filter, setFilter ] = useState<string>('');
    const [ debouncedFilter, setDebouncedFilter ] = useState<string>(filter);
    const { selected } = useSourceContext();

    const tagifyRef = useRef<any>();
    const query = useQuery(createSuggestionsAction(props.query, urls.suggester_filter, selected.join(','), debouncedFilter));

    useEffect(() => {
        setFilter('');
        setDebouncedFilter('');
    }, [ props.searchElements ]);

    useDebounce(() => {
        if (shouldApplySuggestionQuery(filter)) {
            setDebouncedFilter(filter);
        }
    }, 300, [ filter ]);

    // Todo: Port this logic over the new code. it's more robust than what we have there.
    // Reconcile tags. We might need to add new ones or update old ones to conform to the expected content.
    useEffect(() => {
        const elements = props.searchElements;
        const tagElements = tagifyRef.current.getTagElms();

        const innerTagData = (e: any) => e.__tagifyTagData;

        const elementIdSet = new Set<string>(
            [
                ...elements.map(e => e.id),
                ...tagElements.map(innerTagData).map((e: any) => (e.id ?? e.value) as string)
            ]
        );

        const createTagElement = (element: SelectedSearchElement) => ({
            ...element,
            value: element.id,
            original: element
        });

        elementIdSet.forEach(elementId => {
            const element = elements.find(e => e.id === elementId);
            const tagElement = tagElements.find((e: any) => {
                const data = innerTagData(e);
                return (data.id ?? data.value) === elementId;
            });

            if (element && tagElement) {
                // Found in both, check if we need to update
                if (tagElement.id !== element.id) {
                    tagifyRef.current.replaceTag(tagElement, createTagElement(element));
                }
            } else if (element) {
                // Found only in our elements, we need to add to tagify.
                tagifyRef.current.addTags([ createTagElement(element) ]);

            } else if (tagElement) {
                // Found only in tagify. Delete from it.
                tagifyRef.current.removeTags([ tagElement ]);
            }
        });
    }, [ props.searchElements, tagifyRef ]);

    useEffect(() => {
        if (tagifyRef.current && debouncedFilter !== '') {
            const loading = query.loading;
            if (loading) {
                tagifyRef.current.loading(true).dropdown.hide();
                tagifyRef.current.whitelist = [];
            } else {
                tagifyRef.current.loading(false);
                tagifyRef.current.dropdown.maxItems = 15;
            }
        }
    }, [ query.loading, tagifyRef, debouncedFilter ]);

    useEffect(() => {
        const payload = query.payload;
        const status = query.status;

        if (status === 200 && tagifyRef.current && debouncedFilter !== '') {
            tagifyRef.current.whitelist = [ [ debouncedFilter, -1 ] ].concat(Object.entries(payload)).map(([ value, count ]) => ({
                value,
                count,
                searchBy: debouncedFilter
            }));

            tagifyRef.current.dropdown.show();
        }
    }, [ query.payload, query.status, debouncedFilter ]);

    return (
        <FormGroup  label={ <span>Filter { infoKeywordsHelp() }</span> }
            fieldId={ id }
            placeholder={ placeholderKeywords }
        >
            <InputGroup className={ tagsInputGroupClassName }>
                <Tags
                    tagifyRef={ tagifyRef }
                    id={ id }
                    name={ id }
                    aria-describedby={ id }
                    settings={ {
                        editTags: false,
                        templates: {
                            tag: template,
                            dropdownItem: suggestionItemTemplate
                        },
                        callbacks: {
                            input: (e: any) => {
                                setFilter(e.detail.value);
                            },
                            add: (e: any) => {
                                if (!e.detail.data.id) {
                                    props.addKeyword({
                                        id: e.detail.data.value.toLowerCase(),
                                        displayName: e.detail.data.value,
                                        type: SearchElementType.STRING
                                    });
                                }
                            },
                            remove: (e: any) => {
                                return props.removeKeyword(e.detail.data);
                            }
                        }
                    } }
                />
                { props.searchElements.length !== 0 ?
                    <Button variant="control" onClick={ () =>  props.removeKeyword && props.searchElements.forEach(k => props.removeKeyword(k)) }>
                        <TimesCircleIcon/>
                    </Button> : <></> }
                <ShareQueryContainer pubmedQuery={ props.query } searchElements={ props.searchElements }/>
            </InputGroup>
        </FormGroup>
    );
};
