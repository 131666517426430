export const getDateStr = (dateStr: string): string => {
    const dateObj = new Date(dateStr);
    const year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(dateObj);
    const month = new Intl.DateTimeFormat('en', { month: 'short' }).format(dateObj);
    const day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(dateObj);

    return `${year} ${month} ${day}`;
};

export const formatAuthorsInNLM = (authorListStr: string): string => {
    const authorsList = authorListStr.split(',', 5);
    const formattedAuthorsList = authorsList.map(author => {
        const authorPart = author.split(' ');
        if (authorPart.length === 2) {
            return `${ authorPart[1] } ${ authorPart[0] }`;
        }

        else {return author;}
    });

    return formattedAuthorsList.join(', ');
};
