import Config from '../Config';
import { Action } from 'react-fetching-library';

export const urls = Config.apis.urls;

export type Method = 'GET' | 'HEAD' | 'POST' | 'PUT' | 'PATCH' | 'DELETE' | 'OPTIONS';
export enum AuthOptions {
    REQUIRED,
    OPTIONAL,
    DISABLED
}

export interface ActionExtension {
    auth: AuthOptions;
}

interface CreateActionData {
    method: Method;
    url: string;
    auth: AuthOptions;
    queryParams?: Record<string, string>;
    data?: any;
}

export type ActionExtended<R = any> = Action<R, ActionExtension>;

export const createAction = (options: CreateActionData): ActionExtended => {
    return {
        method: options.method,
        endpoint: options.url + (options.queryParams ? '?' + new URLSearchParams(options.queryParams).toString() : ''),
        body: options.data,
        config: {
            auth: options.auth
        }
    };
};
