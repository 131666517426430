import * as React from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { PaperViewerTitle } from './PaperViewer/PaperViewerTitle';
import { style } from 'typestyle';
import { Mixpanel } from '../Components/Mixpanel/MixpanelWrapper';
import download from 'in-browser-download';

import {
    Split,
    SplitItem,
    Button,
    ButtonVariant,
    Stack,
    StackItem
} from '@patternfly/react-core';
import {CloseIcon, CopyIcon, DotCircleIcon, DownloadIcon, TrashIcon} from '@patternfly/react-icons';
import CopyToClipboard from 'react-copy-to-clipboard';
import { PaperViewerInLine } from './PaperViewer/PaperViewerInLine';
import { formatAuthorsInNLM } from '../Utils/PaperUtils';
import { PaperData } from '../Types/PaperData';

interface ViewerProps {
    hideButtons?: boolean;
    items: ReadonlyArray<PaperData>;
    onDragEnd: (result: any) => void;
    onClicked: (result: any) => void;
    onDelete: (result: any) => void;
    onDeleteAll: () => void;
    onSelectAll: () => void;
}

interface ItemProps {
    hideButtons?: boolean;
    item: PaperData;
    index: number;
    onClicked: (result: any) => void;
    onDelete: (result: any) => void;
}

const GRID_SIZE = 10;

const draggingItemClassName = style({
    background: '#f0fff4 !important'
});

const itemClassName = style({
    background: '#f0fbff',
    padding: GRID_SIZE * 0.75,
    margin: `0 0 ${GRID_SIZE}px 0`,
    overflow: 'hidden'
});

const itemDraggableClassName = style({
    userSelect: 'none'
});

const listClassName = style({
    background: '#fff',
    paddingBottom: '10px',
    padding: GRID_SIZE,
    width: '100%',
    fontSize: '0.8em'
});

const listDraggingClassName = style({
    background: '#fff !important',
    paddingBottom: '70px !important'
});

const getTextByItem = (item: PaperData): string => {
    let paper = (item.author) ? formatAuthorsInNLM(item.author) : 'Authors not found';
    paper += '. ';
    paper += item.title;
    paper += ' ';
    paper += item.journal;
    paper += (item.date) ? `. ${ item.date }` : '';
    paper += `. PubMed PMID: ${ item.pmid } `;
    paper += item.doi;

    return paper.replace(/<[^>]+>/g, '');
};

const PaperItem: React.FunctionComponent<ItemProps> = (props) => {
    const [ isVisibleCopiedLabel, setIsVisibleCopiedLabel ] = React.useState<boolean>(false);
    const { item } = props;
    const index =  props.index;

    const textToCopy = React.useMemo(() => {
        return getTextByItem(item);
    }, [ item ]);

    const copiedClassName = style({
        position: 'absolute',
        marginTop: '17px',
        marginLeft: '-50px',
        fontWeight: 'bold'
    });

    const onCopyPaper = React.useCallback((_text: string) => {
        setIsVisibleCopiedLabel(true);
        setTimeout(function () {

            Mixpanel.track({
                name: 'Copy paper',
                props: {
                    paper: {
                        pmid: item.pmid,
                        title: item.title
                    }
                }
            });

            setIsVisibleCopiedLabel(false);
        }, 1500);
    }, [ setIsVisibleCopiedLabel, item ]);

    const buttonsSection = () => {
        if (props.hideButtons && props.hideButtons) {
            return '';
        } else {
            return (
                <SplitItem >
                    <Stack>
                        <StackItem>
                            <CloseIcon aria-label="delete" title="Delete" onClick={ () => { props.onDelete({ index }); } }/>
                        </StackItem>
                        <StackItem>
                            <CopyToClipboard text={ textToCopy } onCopy={ onCopyPaper }>
                                <CopyIcon />
                            </CopyToClipboard>
                        </StackItem>
                        {
                            (isVisibleCopiedLabel) ?
                                <StackItem className={ copiedClassName }>Copied</StackItem> : ''
                        }
                    </Stack>
                </SplitItem>
            );
        }
    };

    // FIXME There the string "Journal" left hanging when a trial is added to list
    return (
        <Split hasGutter>
            <SplitItem isFilled
                onClick={ () => {
                    props.onClicked(item);
                } }
            >
                <PaperViewerTitle paper={ item } renderLink={ true } higthlighting={ false }/>
                <PaperViewerInLine label="Journal" text={ item.journal } higthlighting={ false }/>
            </SplitItem>
            { buttonsSection() }
        </Split>
    );
};

export const PapersList: React.FunctionComponent<ViewerProps> = (props) => {
    const [ isDraggingMode ] = React.useState<boolean>(false);
    const [ isVisibleCopiedLabel, setIsVisibleCopiedLabel ] = React.useState<boolean>(false);

    const listElements = () => {
        // Todo: This is never true, is it?
        if (isDraggingMode) {
            return (
                <>
                    <DragDropContext onDragEnd={ props.onDragEnd } >
                        <Droppable droppableId="droppable">
                            {(provided, snapshot) => (
                                <div
                                    className={ [
                                        snapshot.isDraggingOver ? listDraggingClassName : '',
                                        listClassName,
                                        itemDraggableClassName
                                    ].join(' ') }
                                    ref={ provided.innerRef }
                                    { ...provided.droppableProps }
                                >
                                    {props.items.map((item, index) => (
                                        <Draggable
                                            key={ item.pmid }
                                            draggableId={ (item.pmid !== undefined) ? item.pmid : index + ''  }
                                            index={ index }
                                        >
                                            {(provided, snapshot) => {
                                                return (

                                                    <div>
                                                        <div
                                                            className={ [
                                                                snapshot.isDragging ? draggingItemClassName : '',
                                                                itemClassName
                                                            ].join(' ') }
                                                            ref={ provided.innerRef }
                                                            { ...provided.dragHandleProps }
                                                            { ...provided.draggableProps }
                                                            { ...provided.draggableProps.style }
                                                            //style={ provided.draggableProps.style }
                                                        >
                                                            <PaperItem
                                                                index={ index }
                                                                item={ item }
                                                                onClicked={ props.onClicked }
                                                                onDelete={ props.onDelete }
                                                            />
                                                        </div>
                                                    </div>
                                                );}}
                                        </Draggable>
                                    ))}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                </>
            );
        } else {
            return (
                <div className={ listClassName }>
                    { props.items.map((item, index) => (
                        <div key={ `${index}_${item.pmid}` } className={ itemClassName }>
                            <PaperItem
                                index={ index }
                                item={ item }
                                onClicked={ props.onClicked }
                                onDelete={ props.onDelete }
                                hideButtons={ props.hideButtons }
                            />
                        </div>
                    )) }
                </div>
            );
        }

    };

    const separator = '\r\n';

    const copiedTexts = props.items.map((item) => {
        return getTextByItem(item);
    });

    const onCopyPaper = React.useCallback((_text: string) => {
        setIsVisibleCopiedLabel(true);
        setTimeout(function () {

            Mixpanel.track({
                name: 'Copy all paper list',
                props: {
                    papersNumber: props.items.length,
                    papers: props.items.map((paper) => {
                        return {
                            pmid: paper.pmid,
                            title: paper.title
                        };
                    })
                }
            });

            setIsVisibleCopiedLabel(false);
        }, 1500);
    }, [ setIsVisibleCopiedLabel, props.items ]);

    const onDownloadSelectedPaper = React.useCallback(() => {
        const ids = props.items.map(i => i.pmid);

        fetch('/api/download-csv', {
            method: 'POST',
            body: JSON.stringify(ids),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(response =>  response.text())
        .then(content => download(content, `publications-${new Date().toISOString()}.csv`));
    }, [ props.items ]);

    return (
        <div>
            <div className={ listClassName }>
                <Split hasGutter>
                    {
                        (isVisibleCopiedLabel) ?
                            <SplitItem>
                                <Button variant="link" isDisabled>
                                    Copied
                                </Button>
                            </SplitItem>
                            :
                            ''
                    }
                    <SplitItem>
                        <Button variant={ ButtonVariant.tertiary } onClick={ props.onSelectAll }>
                            <DotCircleIcon /> Select all
                        </Button>
                        &nbsp;
                        <CopyToClipboard text={ copiedTexts.join(separator) } onCopy={ onCopyPaper }>
                            <Button variant={ ButtonVariant.tertiary } isDisabled={ props.items.length === 0 }>
                                <CopyIcon /> Copy
                            </Button>
                        </CopyToClipboard>
                        &nbsp;
                        <Button onClick={ onDownloadSelectedPaper } variant={ ButtonVariant.tertiary } isDisabled={ props.items.length === 0 }>
                            <DownloadIcon /> CSV download
                        </Button>
                    </SplitItem>
                    <SplitItem isFilled />
                    <SplitItem>
                        <Button onClick={ props.onDeleteAll } variant={ ButtonVariant.tertiary } isDisabled={ props.items.length === 0 }>
                            <TrashIcon color="rosybrown" />
                            <span style={ { color: 'rosybrown', marginLeft: 3 } }>Clear</span>
                        </Button>
                    </SplitItem>
                </Split>

            </div>
            { listElements() }
        </div>
    );
};
