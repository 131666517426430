import * as React from 'react';
import {
    Dropdown,
    DropdownGroup,
    DropdownItem, DropdownToggle,
    FormGroup,
    Button,
    InputGroup,
    InputGroupText,
    Popover,
    Spinner,
    Select,
    SelectVariant,
    SelectOption,
    SelectOptionObject
} from '@patternfly/react-core';
import { CaretDownIcon, QuestionCircleIcon, TimesCircleIcon } from '@patternfly/react-icons';
import { popoverIconClassName } from '../SearchForm';
import {useEffect, useState} from "react";
import {useQuery} from "react-fetching-library";
import {AuthOptions, createAction, urls} from "../../Services/Api";
import {useDebounce} from "react-use";
import {useSourceContext} from "../../Pages/Context/Source/SourceContext";

export interface PapersQueryProps {
    query: string;
    onChange: (papersQuery: string) => void;
    count: number | undefined;
    loading: boolean;
    placeholderSearchTerm?: string;
    isEditablePubmedQuery?: boolean;
    scheduleQuery: () => void;
    addNewSearch?: (searchTerm: string, query: PapersQueryBase) => void;
    preloadedQueries?: ReadonlyArray<PapersQueryBase>;
}

export interface PapersQueryBase {
    pubmedQuery: string;
    keywords: ReadonlyArray<string>;
    entities: ReadonlyArray<{
        id: string;
        displayName: string;
    }>;
}
interface PapersFoundProps {
    count?: number;
    loading?: boolean;
    style?: React.CSSProperties;
}

export const createSuggestionsAction = (query: string, url: string, sources: string, keywords?: string) => {
    const queryParams: Record<string, string> = {
        search_term: query,
        sources
    };

    if (keywords) {
        queryParams.keywords = keywords;
    }

    return createAction({
        method: 'GET',
        url,
        queryParams,
        auth: AuthOptions.OPTIONAL
    });
};

export const shouldApplySuggestionQuery = (query: string) => {
    const reservedWords: Array<string> = [ 'and' ];
    const minLength = 2;

    if (query.length < minLength) {
        return false;
    }

    if (reservedWords.includes(query.toLowerCase())) {
        return false;
    }

    return true;
}

export const PapersFound: React.FunctionComponent<PapersFoundProps> = (props) => {
    if (props.loading) {
        return (
            <InputGroupText style={ props.style }>
            <Spinner style={ {
                '--pf-c-spinner--Color': '#fec44f',
                '--pf-c-spinner--stroke-width-multiplier': '0.3'
            } as any }  size="sm" />
            </InputGroupText>
        );
    }

    if (props.count !== undefined) {
        return (
            <InputGroupText style={ props.style }>
                Publications: { props.count }
            </InputGroupText>
        );
    }

    return null;
};

export const InputPapersQuery: React.FunctionComponent<PapersQueryProps> = (props) => {
    const [ isOpenDropdown, setIsOpenDropdown ] = React.useState<boolean>(false);
    const inputPuebmedQueryRef = React.createRef<HTMLInputElement>();

    const onDropdownItemClicked = React.useCallback((pubmedQuery: string, query: PapersQueryBase): void => {
        if (props.addNewSearch !== undefined) {
            props.addNewSearch(pubmedQuery, query);
        }

        props.scheduleQuery();
        setIsOpenDropdown(false);

        if (inputPuebmedQueryRef.current) {
            inputPuebmedQueryRef.current.focus();
        }
    }, [ inputPuebmedQueryRef, props ]);

    const getDropdownItem = (queryBase: PapersQueryBase) => {
        const query = queryBase.pubmedQuery;

        return (
            <DropdownItem key={ query }
                component="button"
                onClick={ () => {
                    onDropdownItemClicked(query, queryBase);
                } }
                className="item-strong-higthlight"
            >
                <span dangerouslySetInnerHTML={ { __html: query }  } />
            </DropdownItem>
        );
    };

    const dropdownItems = (props.preloadedQueries && props.preloadedQueries.length > 0) ? [
        <DropdownGroup label={ false } key="group 1">
            { props.preloadedQueries?.map(query => {
                return getDropdownItem(query);
            }) }
        </DropdownGroup>
    ] : [];

    const onKeyPressEnterRunInput = (event: any) => {
        if (event.key === 'Enter') {
            props.scheduleQuery();
        }
    };

    const onToggleDropdown = React.useCallback(()=>{
        setIsOpenDropdown(!isOpenDropdown);
    }, [ isOpenDropdown ]);

    const isReadOnly = (props.isEditablePubmedQuery !== undefined) ? !props.isEditablePubmedQuery : true;

    // We shouldn't add this kind of queries next to the component level. But I'm considering this code as a playground
    // or PoC. With that said, lets go!

    const [ selectIsOpen, setSelectIsOpen ] = useState<boolean>(false);
    const [ options, setOptions ] = useState<Array<React.ReactElement>>([]);
    const [ filter, setFilter ] = useState<string>('');
    const [ fullQuery, setFullQuery ] = useState<string>('');
    const [ debouncedFilter, setDebouncedFilter ] = useState<string>(filter);

    const onFilter = (_e: unknown, value: string) => {
        const words = value.split(' ');
        setFilter(words[words.length - 1]);
        setFullQuery(words.slice(0, words.length - 1).join(' '));
        return undefined;
    };

    const { selected } = useSourceContext();
    const query = useQuery(createSuggestionsAction(debouncedFilter, urls.suggester, selected.join(',')));

    useDebounce(() => {
        if (shouldApplySuggestionQuery(filter)) {
            setDebouncedFilter(filter);
        }
    }, 300, [ filter ]);

    useEffect(() => {
        const payload = query.payload;
        const status = query.status;

        if (status === 200) {
            setOptions([[ filter, -1 ]].concat(Object.entries(payload)).map(([ value, count ]) => (
                <SelectOption
                    key={ value }
                    value={ `${fullQuery} ${value}` }
                    description={ count as number > 0 ? <>{count}</> : undefined }
                >
                    <b>{value}</b>
                </SelectOption>
            )));
        }
    }, [ query.payload, query.status, filter, fullQuery ]);

    const onSelect = (_event: unknown, val: string | SelectOptionObject, isPlaceholder?: boolean) => {
        if (!isPlaceholder) {
            props.onChange(val.toString());
            setSelectIsOpen(false);
            setOptions([]);
        }
    };

    const inputSearch =
        <>
            <Select
                id="papers-query"
                name="papers-query"
                selections={ props.query }
                variant={ SelectVariant.typeahead }
                placeholder={ props.placeholderSearchTerm }
                onToggle={ () => setSelectIsOpen(prev => !prev) }
                isDisabled={ isReadOnly }
                onFilter={ onFilter }
                isOpen={ selectIsOpen }
                loadingVariant={ query.loading ? 'spinner' : undefined }
                onSelect={ onSelect }
                className="paper-selector"
            >
                {options}
            </Select>

            { props.query !== '' &&  <Button style={{height: 30}} variant="control" onClick={ () => props.onChange && props.onChange('') }>
                <TimesCircleIcon/>
            </Button> }
        </>;

    const dropdownToggle =
        <DropdownToggle onToggle={ onToggleDropdown } icon={ CaretDownIcon }>
            Example queries
        </DropdownToggle>;

    if (props.preloadedQueries && props.preloadedQueries.length > 0) {
        return (
            <InputGroup>
                <Dropdown
                    toggle={ dropdownToggle }
                    isOpen={ isOpenDropdown }
                    dropdownItems={ dropdownItems }
                />
                { inputSearch }
                <PapersFound style={ {height: 30} } count={ props.count } loading={ props.loading }/>
            </InputGroup>
        );
    } else {
        return (
            <InputGroup>
                { inputSearch }
                <PapersFound count={ props.count } loading={ props.loading }/>
            </InputGroup>
        );
    }

};

export const PapersQuery: React.FunctionComponent<PapersQueryProps> = (props) => {

    const infoPubmedQueryHelp = () => {
        return (
            <Popover
                bodyContent={ <div> Query scientific Publications for example:  meningitis AND viral  </div> }
                aria-label="Popover with Link"
                closeBtnAriaLabel="Close Popover with Link"
                position="bottom"
            >
                <QuestionCircleIcon className={ popoverIconClassName } />
            </Popover>
        );
    };

    return (
        <FormGroup label={ <span>Search { infoPubmedQueryHelp() }</span> }
            fieldId="papers-query"
            placeholder={ props.placeholderSearchTerm }
            style={ { marginBottom: 10 } }
        >
            <InputPapersQuery
                query={ props.query }
                onChange={ props.onChange }
                count={ props.count }
                loading={ props.loading }
                placeholderSearchTerm={ props.placeholderSearchTerm }
                scheduleQuery={ props.scheduleQuery }
                addNewSearch={ props.addNewSearch }
                preloadedQueries={ props.preloadedQueries }
                isEditablePubmedQuery={ props.isEditablePubmedQuery }
            />
        </FormGroup>
    );
};
