import * as React from 'react';
import { useKeycloak } from '@react-keycloak/web';
import avatarImg from '../../App/avatarImg.svg';
import {Avatar, Dropdown, DropdownItem, DropdownSeparator, DropdownToggle, Skeleton} from '@patternfly/react-core';

export const ProfileDropdown: React.FunctionComponent = () => {

    const { keycloak } = useKeycloak();
    const [ isOpen, setOpen ] = React.useState(false);
    const onToggle = () => setOpen(prev => !prev);
    const [ username, setUsername ] = React.useState<string>();

    React.useEffect(() => {
        if (keycloak.authenticated) {
            keycloak.loadUserProfile().then(profile => {
                setUsername(profile?.username);
            });
        }
    }, [ keycloak.authenticated ]);

    const dropdownItems = [
        <DropdownItem key="user">
            {username ?? <Skeleton width="50px" />}
        </DropdownItem>,
        <DropdownSeparator key="separator" />,
        <DropdownItem key="logout" component="button" onClick={ () => keycloak.logout() }>
            Logout
        </DropdownItem>
    ];

    return (
        <Dropdown
            toggle={ <DropdownToggle toggleIndicator={null} style={ { paddingTop: 3 } } onToggle={ onToggle } aria-label="profile">
                <Avatar style={ { width: 24, height: 24 } } src={ avatarImg } alt="profile"/>
            </DropdownToggle> }
            isOpen={ isOpen }
            isPlain
            dropdownItems={ dropdownItems }
            menuAppendTo={ document.body }
            position="right"
        />
    );
};
