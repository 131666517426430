import * as React from 'react';

export const useSchedule = (job: Function, scheduleUponCreation = false) => {
    const [ scheduleFlag, setScheduleFlag ] = React.useState<boolean>(scheduleUponCreation);

    const scheduleUpdate = React.useCallback(() => {
        setScheduleFlag(true);
    }, [ setScheduleFlag ]);

    React.useEffect(() => {
        if (scheduleFlag) {
            job();
            setScheduleFlag(false);
        }
    }, [ scheduleFlag, job ]);

    return scheduleUpdate;
};
