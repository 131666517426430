import * as React from 'react';
import {
    Nav,
    NavList,
    PageHeader,
    Brand,
    ListVariant,
    List,
    ListItem, Button, ButtonVariant, Avatar, DropdownToggle
} from '@patternfly/react-core';
import { useHistory } from 'react-router-dom';
import { QuickStartText } from '../PageParts/QuickStartText';
import { NavLinkModal } from './NavLinkModal';
import { InstructionsText } from '../PageParts/InstructionsText';
import {ProfileDropdown} from "./ProfileDropdown";
import {useKeycloak} from "@react-keycloak/web";

export enum OpenModalVariant {
    quickStartModal = 'quickStart',
    helpModal = 'help'
}

interface ViewProps {
    showModal?: OpenModalVariant.quickStartModal | OpenModalVariant.helpModal;
}

export const PubmedNavBar: React.FunctionComponent<ViewProps> = (props) => {
    const { keycloak } = useKeycloak();

    const quickStartLink =
        <NavLinkModal
            linkTitle="Quickstart"
            showClose={ true }
            disableFocusTrap={ false }
            isOpen={ (props.showModal && props.showModal === OpenModalVariant.quickStartModal) }
        >
            <QuickStartText />
        </NavLinkModal>
    ;

    const helpLink =
        <NavLinkModal
            linkTitle="Help"
            title="Getting started"
            showClose={ true }
            disableFocusTrap={ false }
            width="85%"
            isOpen={ (props.showModal && props.showModal === OpenModalVariant.helpModal) }
        >
            <InstructionsText />
        </NavLinkModal>
    ;

    const nav =
    <Nav>
        <NavList>
            <List variant={ ListVariant.inline }>
                <ListItem>
                    { quickStartLink }
                </ListItem>
                <ListItem>
                    { helpLink }
                </ListItem>
                <ListItem style={ { paddingRight: 15 } }>
                    { keycloak.authenticated && <ProfileDropdown /> }
                </ListItem>
            </List>
        </NavList>
    </Nav>;

    const history = useHistory();

    const brand = <Brand onClick={ () => { history.push('/'); } } src='/logo.svg' alt="Scicarta" />;

    const topNav = <div>skim science quickly</div>;

    return (
        <PageHeader
            id="main-nav"
            className="scicarta-navbar"
            logo={ brand }
            headerTools={ nav }
            topNav={ topNav }
        />
    );
};
