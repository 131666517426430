export interface RGBColor {
    r: number;
    g: number;
    b: number;
}

export interface PaletteColorData {
    accent: HtmlColor;
    pastel: HtmlColor;
}

export const hexToRgb = (hex: string|undefined): RGBColor|undefined => {
    if (hex === undefined) {
        return undefined;
    }

    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    } : undefined;
};

export class HtmlColor {
    readonly color: RGBColor|undefined;

    public static fromHexString(hex: string|undefined) {
        return new HtmlColor(hexToRgb(hex));
    }

    constructor(color: RGBColor|undefined) {
        this.color = color;
    }

    public toString() {
        if (this.color === undefined) {
            return '';
        }

        return `rgb( ${this.color.r}, ${this.color.g}, ${this.color.b})`;
    }

    public toStringColorWithAlpha(alpha: number) {
        if (this.color === undefined) {
            return '';
        }

        return `rgba( ${this.color.r}, ${this.color.g}, ${this.color.b}, ${ alpha })`;
    }
}

export class ColorPalette {
    static readonly colors = [
        {
            accent: HtmlColor.fromHexString('#e41a1c'),
            pastel: HtmlColor.fromHexString('#fbb4ae')
        },
        {
            accent: HtmlColor.fromHexString('#377eb8'),
            pastel: HtmlColor.fromHexString('#b3cde3')
        },
        {
            accent: HtmlColor.fromHexString('#4daf4a'),
            pastel: HtmlColor.fromHexString('#ccebc5')
        },
        {
            accent: HtmlColor.fromHexString('#984ea3'),
            pastel: HtmlColor.fromHexString('#decbe4')
        },
        {
            accent: HtmlColor.fromHexString('#ff7f00'),
            pastel: HtmlColor.fromHexString('#fed9a6')
        },
        {
            accent: HtmlColor.fromHexString('#a65628'),
            pastel: HtmlColor.fromHexString('#e5d8bd')
        },
        {
            accent: HtmlColor.fromHexString('#f781bf'),
            pastel: HtmlColor.fromHexString('#fddaec')
        }
    ]

    static getColor = (index: number): PaletteColorData => {
        const color =  ColorPalette.colors[ index % ColorPalette.colors.length ];
        return color;
    }
}
