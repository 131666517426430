import * as React from 'react';
import { AnchorHTMLAttributes } from 'react';
import TreeMenu, { MatchSearchFunction, TreeMenuItem, TreeNodeInArray } from 'react-simple-tree-menu';
import { Title, TextInput } from '@patternfly/react-core';
import './EntitiesMenu.css';
import { SearchElement, SearchElementType } from '../Types/Search';
import { ChevronRightIcon, ChevronDownIcon } from '@patternfly/react-icons';
import { style } from 'typestyle';

export interface Entity {
    type: 'entity';
    id: string;
    name: string;
    count: number;
    words: Array<string>;
}

export interface EntityGroup {
    type: 'group';
    name: string;
    contents: Array<EntityGroup | Entity>;
}

export type Entities = ReadonlyArray<EntityGroup | Entity>;

export interface EntitiesMenuProps {
    entities: Entities;
    showEntities: Array<SearchElement>;
    addEntity: (element: SearchElement) => void;
    removeEntity: (element: SearchElement) => void;
}

const menuItemClassName = style({
    cursor: 'pointer'
});

const inputClassName = style({
    marginBottom: 10
});

const isSelected = (entities: Array<SearchElement>, id: any) => entities.findIndex(
    value => value.id === id
) !== -1;

export const NoFocusAnchor: React.FunctionComponent<AnchorHTMLAttributes<HTMLAnchorElement>> = (props) => {
    const newProps = {
        ...props,
        onClick: (ev: any) => {
            ev.target.blur();
            return props.onClick && props.onClick(ev);
        }
    };
    return <span { ...newProps }>{ props.children }</span>;
};

const countReducer = (count: number, value: EntityGroup | Entity): number => {
    if (value.type === 'entity') {
        return count + value.count;
    } else if (value.type === 'group') {
        return count + value.contents.reduce(countReducer, 0);
    } else {
        throw new Error('Invalid value found on entities' + JSON.stringify(value));
    }
};

const FIRST_AUTHOR = 'First author:';
const LAST_AUTHOR = 'Last author:';

const KEY_SEPARATOR = '~~';
const computeKey = (key: string, parent?: string): string => {
    return parent ? parent + KEY_SEPARATOR + key : key;
};

const expandParent = (value: TreeNodeInArray, parent: TreeNodeInArray): TreeNodeInArray => {
    value.parent = parent.parent ? parent.parent + KEY_SEPARATOR + parent.key : parent.key;
    return value;
};

const searchContent = (search: string, label: string) => {
    return label.toLowerCase().includes(search);
};

const getNode = (key: string, nodes: Array<TreeNodeInArray>) => nodes.find(n => n.key === key);

const getNodeFromPath = (pathString: string, data: Array<TreeNodeInArray>) => {
    const path = pathString.split(KEY_SEPARATOR);
    let node = getNode(path[0], data);

    for (let i = 1; i < path.length; ++i) {
        if (!node || !node?.nodes) {
            throw new Error('Invalid node found');
        }

        node = getNode(path[i], node.nodes);
    }

    if (!node) {
        throw new Error('Not found found');
    }

    return node;
};

const entityMap = (value: EntityGroup | Entity, parent?: TreeNodeInArray): TreeNodeInArray => {
    if (value.type === 'entity') {
        const entity = {
            key: value.id,
            label: value.name,
            count: value.count,
            words: value.words,
            id: value.id,
            isOpen: true
        };
        if (parent) {
            expandParent(entity, parent);
        }

        return entity;
    } else if (value.type === 'group') {
        const group: TreeNodeInArray = {
            key: `${value.name}-group`,
            label: value.name,
            nodes: [],
            isOpen: true,
            count: value.contents.reduce(countReducer, 0)
        };
        if (parent) {
            expandParent(group, parent);
        }

        if (group.nodes !== undefined) {
            group.nodes.push(...value.contents.map(e => entityMap(e, group)));
        }

        return group;

    } else {
        throw new Error('Invalid value found on entities' + JSON.stringify(value));
    }
};

interface EntityItemProps extends TreeMenuItem {
    label: string;
    selected: boolean;
}

export const EntityItem: React.FunctionComponent<EntityItemProps> = props => {
    const Component = React.useMemo(() => {
        if (props.selected) {
            return 'b';
        }

        return 'span';
    },  [ props.selected ]);

    const count = (props as any).count ?? 0;

    let label = props.label;

    if (label.startsWith(FIRST_AUTHOR)) {
        label = label.slice(FIRST_AUTHOR.length);
    } else if (label.startsWith(LAST_AUTHOR)) {
        label = label.slice(LAST_AUTHOR.length);
    }

    return (
        <li className={ menuItemClassName } onClick={ props.hasNodes ? props.toggleNode : props.onClick } style={ {
            paddingLeft: `${props.level + (props.hasNodes ? 0 : 1)}em`
        } }>
            <Component>
                { props.hasNodes && (props.isOpen ? <ChevronDownIcon/> : <ChevronRightIcon/>) }
                { label } { count ? ` - ${count}` : ''}
            </Component>
        </li>
    );
};

export const EntitiesMenu: React.FunctionComponent<EntitiesMenuProps> = ({ addEntity, removeEntity, showEntities, entities }) => {
    const onItemClick = React.useCallback((element) => {
        if (element.hasNodes) {
            return;
        }

        const id = element.id;
        let isInSelected = showEntities.findIndex(value => value.type === SearchElementType.ENTITY && value.id === id) !== -1;

        let entityType = SearchElementType.ENTITY;
        if  (element.parent.startsWith('Industry-group') || element.parent.startsWith('Authors-group') ||
            element.parent.startsWith('Other-group')) {
            entityType = SearchElementType.STRING;
            isInSelected = showEntities.findIndex(value => value.type === SearchElementType.STRING && value.id === id) !== -1;
        }

        if (isInSelected) {
            removeEntity({
                id,
                type: entityType,
                displayName: element.label
            });
        } else {
            addEntity({
                id,
                type: entityType,
                displayName: element.label
            });
        }
    }, [ addEntity, removeEntity, showEntities ]);

    const data: Array<TreeNodeInArray> = React.useMemo(() => {
        return entities.map(e => entityMap(e));
    }, [ entities ]);

    const count = React.useMemo(() => entities.reduce(countReducer, 0), [ entities ]);

    let cacheResults: Record<string, boolean> = {};
    const matchSearch: MatchSearchFunction = (matchSearchFunctionProps) => {

        const recursiveSearch = (node: TreeNodeInArray, search: string): boolean => {
            const key = computeKey(node.key, node.parent);
            if (cacheResults.hasOwnProperty(key)) {
                return cacheResults[key];
            }

            let result = searchContent(search, node.label);

            if (node.nodes) {
                for (const childNode of node.nodes) {
                    result = result || recursiveSearch(childNode, search);
                }
            }

            cacheResults[key] = result;

            return result;
        };

        const searchTerm = matchSearchFunctionProps.searchTerm.toLowerCase().trim();
        const key = computeKey(matchSearchFunctionProps.key, matchSearchFunctionProps.parent);

        if (cacheResults.hasOwnProperty(key)) {
            return cacheResults[key];
        }

        const node = getNodeFromPath(key, data);
        cacheResults[key] = recursiveSearch(node, searchTerm);

        return cacheResults[key];
    };

    return (
        <>
            <Title className="entities-title" headingLevel="h4" size="lg"><b>Concepts found:</b> { count }</Title>
            <TreeMenu
                data={ data }
                hasSearch={ true }
                disableKeyboard={ true }
                onClickItem={ onItemClick }
                matchSearch={ matchSearch }
                initialOpenNodes={ [
                    'Industry-group',
                    'Industry-group/Company-group'
                ] }
            >
                {({ search, items }) => (
                    <div>
                        <TextInput
                            className={ inputClassName }
                            type="search"
                            onChange={ e => {
                                cacheResults = {};
                                search && search(e);
                            } }
                            placeholder="Concept live search"
                        />
                        <ul>
                            {items.map(({ key, ...props }) => {
                                return (
                                    <EntityItem key={ key } selected={ isSelected(showEntities, props.id) }  { ...props } />
                                );
                            })}
                        </ul>
                    </div>
                )}
            </TreeMenu>
        </>
    );
};
