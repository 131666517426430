import Keycloak from 'keycloak-js';

// Gets the realm - if
const getRealm = () => {

    if (process.env.REACT_APP_KEYCLOAK_REALM) {
        return process.env.REACT_APP_KEYCLOAK_REALM;
    }

    if (process.env.NODE_ENV === 'production') {
        return 'scicarta';
    }

    return 'scicarta-dev';
};

const keycloak = new Keycloak({
    url: 'https://sso.scicarta.com',
    realm: getRealm(),
    clientId: 'web'
});

export default keycloak;
