import { DeepReadonly } from 'ts-essentials/dist/types';
import { PapersQueryBase } from './Components/SearchForm/PapersQuery';

const apiBaseUrl = '/api';

const withBaseUrl = (path: string) => `${apiBaseUrl}/${path}`;

const getBaseUrl = () => {
    const url = new URL(window.location.href);
    return `${url.protocol}//${url.host}`;
};

const Config = {
    appId: 'pubskape',
    baseUrl: getBaseUrl(),
    apis: {
        urls: {
            base: apiBaseUrl,
            signin: withBaseUrl('login'),
            reset: withBaseUrl('reset/password'),
            verify: (token: string) => withBaseUrl(`verify/${token}`),
            plot: withBaseUrl('plot'),
            suggester: withBaseUrl('suggester'),
            suggester_filter: withBaseUrl('suggester_filter'),
            enrichments: withBaseUrl('enrichments'),
            papersCount: withBaseUrl('get_papers_count')
        }
    },
    placeholderSearchTerm: '',
    appQueries: [    {
        pubmedQuery: 'kras g12c',
        keywords: [
            'combination', 'synergy', 'sotorasib', 'Amgen', 'Novartis'
        ],
        entities: [
            {
                id: 'C0694883',
                displayName: 'STK11 gene'
            },
            {
                id: 'C0007131',
                displayName: 'Non-small cell lung cancer'
            }
        ]
    },{
        pubmedQuery: 'her2',
        keywords: [
            'Astrazeneca', 'Pfizer', 'Novartis', 'Daiichi Sankyo', 'bispecific', 'adc',
            'oral abstract session', 'colorectal and anal'
        ],
        entities: [
            {
                id: 'C1335212',
                displayName: 'PIK3CA gene'
            }
        ]
    },
    {
        pubmedQuery: 'relapsed refractory AML',
        keywords: [
            'Kura Oncology', 'Bristol Myers Squibb', 'Loxo Oncology', 'oral presentation', 'clinical trial',
            'hypomethylating agents'
        ],
        entities: [
            {
                id: 'C0919528',
                displayName: 'KMT2A gene'
            }
        ]
    },
    {
        pubmedQuery: 'AAD',
        keywords: [
            'April Armstrong'
        ],
        entities: [
            {
                id: 'C0025202',
                displayName: 'Malignant melanoma'
            },
            {
                id: 'C0033860',
                displayName: 'Psoriasis'
            },
            {
                id: 'C0011615',
                displayName: 'Dermatitis Atopic'
            },
            {
                id: 'C1421249',
                displayName: 'TYK2 gene'
            }
        ]
    },
    {
        pubmedQuery: 'JAK',
        keywords: [
            'jonathan silverberg', 'thierry passeron'
        ],
        entities: [
            {
                id: 'C0011615',
                displayName: 'Dermatitis Atopic'
            },
            {
                id: 'C0033860',
                displayName: 'Psoriasis'
            },
            {
                id: 'C1421249',
                displayName: 'TYK2 gene'
            },
            {
                id: 'C0042900',
                displayName: 'Vitiligo'
            }
        ]
    },
    {
        pubmedQuery: 'atopic dermatitis',
        keywords: [
            'dermatitis', 'abrocitinib', 'moderate', 'severe', 'safety'
        ],
        entities: [
            {
                id: 'C2931926',
                displayName: 'ruxolitinib'
            }
        ]
    },
    {
        pubmedQuery: 'cd47',
        keywords: [
            'safety', 'combination', 'Phanes Therapeutics'
        ],
        entities: [
            {
                id: 'C0019054',
                displayName: 'Hemolysis (disorder)'
            },
            {
                id: 'C0031308',
                displayName: 'Phagocytosis'
            }
        ]
    },
    {
        pubmedQuery: '"cell therapy"',
        keywords: [
            'car t', 'cell therapy', 'Fate Therapeutics', 'Editas Medicine', 'nk cell', 'tcr', 'Dan S. Kaufman'
        ],
        entities: []
    },
    {
        pubmedQuery: 'embargoed',
        keywords: [],
        entities: []
    },
    {
        pubmedQuery: 'oncolytic virus',
        keywords: [
            'Juan Fueyo', 'Oncorus', 'vector', 'adenovirus', 'hsv', 'delivery'
        ],
        entities: []
    },
    {
        pubmedQuery: 'AstraZeneca | Kymera | Loxo',
        keywords: [ 'AstraZeneca', 'Kymera Therapeutics', 'Loxo Oncology', 'AZD5305', 'adc'
        ],
        entities: [
            {
                id: 'C1538577',
                displayName: 'PARP1 gene'
            }
        ]
    }
    ] as ReadonlyArray<PapersQueryBase>
};

const ReadOnlyConfig: DeepReadonly<typeof Config> = Config;
export default ReadOnlyConfig;
