import amplitude from 'amplitude-js';

const instance = amplitude.getInstance();

// We avoid making calls in development mode
const env_check = process.env.NODE_ENV === 'production';

export interface UserData {
    id: string;
    email: string;
    alias?: string;
}

interface TrackActions {
    identify: (id: string) => void;
    alias: (id: string) => void;
    track: ({ name, props }: { name: string; props?: {} }) => void;
    reset: () => void;
    people: PeopleTrackActions;
    regist: (user: UserData) => void;
}

interface PeopleTrackActions {
    set: ({ props }: { props: {} }) => void;
    append: ({ list, props }: { list: string; props: any }) => void;
}

const noTrackingActions = (): TrackActions => {
    return {
        identify: () => {/**/},
        alias: () => {/**/},
        track: () => {/**/},
        reset: () => {/**/},
        people: {
            set: () => {/**/},
            append: () => {/**/}
        },
        regist: () => {/**/}
    };
};

const buildTrackingActions  = (): TrackActions => {
    return {
        identify: (id: string) => {
            instance.setUserId(id);
        },
        alias: (id: string) => {
            instance.setUserId(id);
        },
        track: ({ name, props }: { name: string; props?: {} }) => {
            instance.logEvent(name, props);
        },
        reset: () => {
            instance.setUserId(null);
            instance.regenerateDeviceId();
        },
        people: {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            set: ({ props }: { props: {} }) => {
                console.error('People.set not implemented yet, does amplitude has it?');
            },
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            append: ({ list, props }: { list: string; props: any }) => {
                console.error('People.append not implemented yet, does amplitude has it?');
            }
        },
        regist: (user: UserData) => {
            buildTrackingActions().identify(user.id);
            if (user.alias) {
                buildTrackingActions().identify(user.alias);
            } else {
                buildTrackingActions().identify(user.email);
            }

            if (user.email) {
                buildTrackingActions().people.set({
                    props: { email: user.email, name: user.email }
                });
            }
        }
    };
};

const actions = env_check ? buildTrackingActions() : noTrackingActions();

if (env_check) {
    const keys = {
        80: '48e047e196bd1af255150958917acf81',
        46835: '1975c9018e023f5b9048fc06e0a9c8dc',
        24680: 'e1bdbd3b68e4b91e377ca9b7d1b42e9e'
    } as any;

    if (keys[window.location.port]) {
        instance.init(keys[window.location.port]);
    } else {
        console.error('Amplitude KEY was not found');
    }
}

export const Mixpanel = actions;
