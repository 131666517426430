import * as React from 'react';
import { PubmedNavBar } from '../Components/Navbar/PubmedNavBar';
import { Page, PageSection, Text, TextContent } from '@patternfly/react-core';
import { style } from 'typestyle';
import { Link } from 'react-router-dom';
import { OutlinedArrowAltCircleLeftIcon } from '@patternfly/react-icons';
import { PubmedFooterBar } from '../Components/Navbar/PubmedFooterBar';

const contentClassName = style({
    maxWidth: '1000px',
    marginLeft: 'auto',
    marginRight: 'auto'
});

const levelTitle = 'h2';

export const PrivacyPolicy: React.FunctionComponent = (_props) => {
    return (
        <React.Fragment>
            <PubmedNavBar />
            <Page className='page' >
                <PageSection variant="light" >

                    <TextContent className={ contentClassName }>
                        <Link className="back-in-page" to="/">
                            <Text component={ 'h2' }>
                                <OutlinedArrowAltCircleLeftIcon /> Back
                            </Text>
                        </Link>

                        <Text component={ 'h1' }>Scicarta Inc. Policy on Privacy</Text>

                        <p>
                            Scicarta, Inc. (&ldquo;Scicarta&rdquo;, &ldquo;we&rdquo;, &ldquo;our&rdquo;
                            or &ldquo;us&rdquo;) understands that privacy is important to our website users.
                            This Privacy Policy sets out how we will collect and use the personal information
                            you provide to us while using the website located
                            at <Link to="/">www.scicarta.com</Link> (the &ldquo;Site&rdquo;). This Privacy Policy
                            applies solely to the Site. It does not apply to any other site, product or service of
                            Scicarta, its affiliates, third party sellers, or customers.
                        </p>
                        <p>
                            By accessing or using the Site, you are deemed to accept the terms of this
                            Privacy Policy. If you do not agree to this Privacy Policy, you may not access or
                            otherwise use the Site. We reserve the right, at our discretion, to change, modify,
                            add or remove portions of this Privacy Policy from time to time and such changes shall
                            be posted on the Site. We encourage you to periodically review the Site for the latest
                            information on our privacy practices.
                        </p>
                        <p>
                            The Site, together with all its content, is owned or controlled by Scicarta, Inc., a
                            corporation organized in the State of Delaware with its office at 585 Massachusetts
                            Avenue, 4th Floor, Cambridge, Massachusetts 02139, USA.
                        </p>

                        <Text component={ levelTitle }>PERSONALLY IDENTIFIABLE INFORMATION THAT WE COLLECT</Text>
                        <p>
                            To provide the Site, we may collect and process certain personal information that you
                            voluntarily submit to us, such as through our contact pages, information request pages,
                            by email, by seeking employment with us, or some other means. We take care to keep this
                            information secure and prevent any unauthorized access or unlawful use of it as described
                            in the &ldquo;Security&rdquo; section below.
                        </p>
                        <p>
                            The personal information you may provide to us could include your name, address,
                            date of birth, gender, email addresses and/or mobile phone numbers.
                        </p>

                        <Text component={ levelTitle }>NON-PERSONAL OR AGGREGATE INFORMATION THAT WE COLLECT</Text>
                        <p>
                            When you visit the Site, we may automatically collect certain non-identifying information
                            about you, such as the type of browser or device operating system you use, the domain name
                            of the website from which you linked to us, the amount of time you spend on the Site and
                            the pages on the Site that you view. We may also aggregate information collected from our
                            users.
                        </p>

                        <Text component={ levelTitle }>INFORMATION USAGE</Text>
                        <p>
                            The information we collect about you or you provide to us will be used to provide you
                            with information and any other services associated with them.
                        </p>
                        <p>In addition, we may use your personal information:</p>
                        <ul>
                            <li>To respond to your direct queries.</li>
                            <li>To operate the Site.</li>
                            <li>To add you to our mailing lists and to send you emails from time to time.</li>
                            <li>To fulfill any other purpose for which you provide it.</li>
                            <li>
                                To carry out our obligations and enforce our rights arising from any contracts
                                entered into between you and us.
                            </li>
                        </ul>
                        <p>
                            You may withdraw your consent to our use of your personal information at any time by
                            contacting us at <a href="mailto:info@scicarta.com">info@scicarta.com</a>
                        </p>

                        <Text component={ levelTitle }>INFORMATION SHARING</Text>
                        <p>
                            We may disclose and market aggregated information about users of our Site, and
                            aggregated information collected via our Site, that does not identify any individual,
                            without restriction.
                        </p>
                        <p>
                            We will only share your personal information as described below, unless you have
                            specifically consented to another type of use, either at the time the personal
                            information is collected from you or through some other form of consent from you.
                        </p>
                        <p>We may disclose your personal information:</p>
                        <ul>
                            <li>To our subsidiaries and affiliates.</li>
                            <li>
                                To contractors, service providers, and other third parties we use to support our
                                business and who are bound by contractual obligations to keep personal information
                                confidential and use it only for the purposes for which we disclose it to them.
                            </li>
                            <li>
                                To a buyer or other successor in the event of a merger, divestiture, restructuring,
                                reorganization, dissolution, or other sale or transfer of some or all of
                                Scicarta&apos;s assets, whether as a going concern or as part of a bankruptcy,
                                liquidation, or similar proceeding, in which personal information held by Scicarta
                                about our users is among the assets transferred.
                            </li>
                            <li>
                                To other third parties, to allow them to market their products or services to you
                                if you have not opted out of these disclosures. We contractually require these third
                                parties to keep your personal information confidential and use it only for the
                                purposes for which we disclose it to them. For more information, see
                                the &ldquo;Opt-Out Process&rdquo; section below.
                            </li>
                            <li>To fulfill any purpose for which you provide it.</li>
                            <li>
                                To comply with any court order, law, or legal process, including to respond to any
                                government or regulatory request.
                            </li>
                            <li>
                                To enforce or apply our contract terms and conditions, including for billing
                                and collection purposes.
                            </li>
                            <li>
                                To investigate suspected fraud, harassment or other violations of any law,
                                rule or regulation, or the policies for the Site.
                            </li>
                        </ul>

                        <Text component={ levelTitle }>COOKIES</Text>
                        <p>
                            We may place small data files on your device or device hard drive known
                            as &ldquo;cookies&rdquo; or a similar type of file (such as clear gifs, web beacons,
                            tags, etc.) for the purpose of facilitating and enhancing your communication and
                            interaction with the Site. Many websites use cookies for these purposes. Cookies are
                            also used to collect general usage and volume statistical information. We use this
                            information internally, to help us maintain and enhance the efficiency and usefulness
                            of the Site. We may also use and place cookies on your device from our third party
                            service providers in connection with the Site, such as an analytics provider that helps
                            us manage and analyze Site usage. We also use session ID cookies and persistent cookies.
                            A session ID cookie expires when you close your browser. Our persistent cookies may
                            remain active on your device for up to 1-3 years (depending upon the purpose of the
                            cookie) or until you delete them manually. We set a persistent cookie to enable us to
                            track and target the interests of users to enhance the services we provide. Cookies and
                            similar items are not used by us to automatically retrieve personal information from
                            your device without your knowledge. If you do not wish to receive cookies or want to
                            restrict them or flush them from your device, you may do so by changing the settings
                            on your computer or browser accordingly, in which case you may still use our Site,
                            but it may interfere with some of its functionality.
                        </p>

                        <Text component={ levelTitle }>CLICKSTREAM DATA</Text>
                        <p>
                            As you use the Internet, a trail of electronic information is left at each website you
                            visit. This information, which is sometimes referred to
                            as &ldquo;clickstream data,&rdquo; can be collected and stored by a website&rsquo;s server.
                            Clickstream data can tell us the type of device and browsing software you use and the
                            address of the website from which you linked to the Site. We may collect and use
                            clickstream data to anonymously determine how much time visitors spend on websites,
                            how visitors navigate throughout websites and how we may tailor our Site to better meet
                            consumer needs. This information will be used to improve our Site. Any collection or
                            use of clickstream data will be anonymous, and will not intentionally contain any
                            personal data.
                        </p>

                        <Text component={ levelTitle }>SECURITY</Text>
                        <p>
                            We have implemented technical and organizational measures, appropriate to the risk,
                            to protect your personal information against accidental or unlawful destruction, loss
                            or alteration and unauthorized disclosure or access. However, due to the inherent open
                            nature of the Internet, we cannot ensure or warrant the security of any information
                            provided online.
                        </p>

                        <Text component={ levelTitle }>OTHER SITES</Text>
                        <p>
                            As a convenience to you, we may provide links to third party websites from within
                            the Site. We are not responsible for the privacy practices or content of these third
                            party sites, and by providing a link we are not endorsing or promoting such third party
                            sites. When you link away from our Site, you do so at your own risk. We encourage you to
                            read the policies and terms of every website you visit.
                        </p>

                        <Text component={ levelTitle }>INFORMATION RELATING TO CHILDREN</Text>
                        <p>
                            Our Site is designed for those 13 years of age and older. We do not knowingly collect
                            information from anyone under the age of 13. If we are made aware that we have received
                            such information, or any information in violation of this Privacy Policy, we will use
                            reasonable efforts to locate and remove that information from our records.
                        </p>

                        <Text component={ levelTitle }>COMMUNICATIONS WITH SCICARTA</Text>
                        <p>By providing your email address to us, you expressly consent to receive emails from us.
                            We may use email to communicate with you, to send information that you have requested or
                            to send information about other services provided by us, provided that, we will not give
                            your email address to another party to promote their products or services directly to you
                            except as set forth in this Privacy Policy.
                        </p>

                        <Text component={ levelTitle }>DO NOT TRACK SIGNALS</Text>
                        <p>
                            Please note that we do not respond to or honor &ldquo;do not track&rdquo; signals or
                            similar mechanisms transmitted by web browsers.
                        </p>

                        <Text component={ levelTitle }>YOUR CALIFORNIA PRIVACY RIGHTS</Text>
                        <p>
                            California law permits customers in California to request certain details about how their
                            information is shared with third parties for those third parties&rsquo; own direct
                            marketing purposes. If you are a California resident, you may request such information
                            from us by contacting us by email
                            at <a href="mailto:info@scicarta.com">info@scicarta.com</a>. Any such
                            request must include &quot;California Privacy Rights Request&quot; in the first line of
                            the description and include your name, street address, city, state, and zip code.
                            Please note that we are only required to respond to one request per customer each year.
                        </p>

                        <Text component={ levelTitle }>QUESTIONS AND COMMENTS</Text>
                        <p>
                            If you have any queries or comments about this Privacy Policy or our privacy practices,
                            or wish to resume receiving information which you may have previously opted-out of
                            receiving, please contact us at info@scicarta.com.
                        </p>
                    </TextContent>
                </PageSection>
            </Page>
            <PubmedFooterBar />
        </React.Fragment>
    );
};
