import * as React from 'react';
import { HighlightText } from '../../Utils/HighLightText';
import { StyledChunks, StylesWords } from '../../Utils/HighLightReconciliation';

interface ViewerProps {
    label: string;
    text?: string;
    searchToHighlight? : Array<StylesWords>;
    styledChunks?: Array<StyledChunks>;
    processComma: boolean;
}

export const PaperViewerPart: React.FunctionComponent<ViewerProps> = (props) => {
    const textData = React.useMemo(() => {
        if (!props.processComma) {
            return props.text ?? '';
        }

        return (props.text === undefined) ? '<span />' : props.text.split(',').join(', ');
    }, [ props.text, props.processComma ]);

    return (
        <>
            <p><strong className='label-text'>{ props.label }</strong></p>
            <HighlightText component={ 'p' } text={ textData } keywords={ props.searchToHighlight } styledChunks={ props.styledChunks } />
        </>
    );
};
