import { AuthOptions, createAction } from './Api';
import { useMutation } from 'react-fetching-library';
import { urls } from './Api';

interface ResetQuery {
    token: string | undefined;
    password: string;
}

const resetPasswordAction = (query: ResetQuery) => {
    return createAction({
        method: 'POST',
        url: urls.reset,
        data: query,
        auth: AuthOptions.DISABLED
    });
};

export const useResetPasswordMutation = () => {
    return useMutation(resetPasswordAction);
};
