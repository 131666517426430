import { AuthOptions, createAction, urls } from './Api';
import { useMutation } from 'react-fetching-library';

interface PlotQuery {
    searchTerm: string;
    keywords: string;
    entities: string;
    sources: string;
}

const createPlotAction = (query: PlotQuery) => {
    return createAction({
        method: 'GET',
        url: urls.plot,
        queryParams: {
            search_term: query.searchTerm,
            optional_keywords: query.keywords,
            entities: query.entities,
            sources: query.sources
        },
        auth: AuthOptions.OPTIONAL
    });
};

export const usePlotMutation = () => {
    return useMutation(createPlotAction);
};
