import { default as React, Dispatch, SetStateAction, useMemo } from 'react';
import { Mixpanel } from '../../Components/Mixpanel/MixpanelWrapper';
import { ScicartaPoint } from '../../Components/Graph';
import { PaperData } from '../../Types/PaperData';
import deepEqual from 'deep-equal';
import { PlotResponse } from '../../Types/Plot';

export const useGraphCallbacks = (
    setPaperLeftViewer: Dispatch<SetStateAction<PaperData>>,
    setPlotResponse: Dispatch<SetStateAction<PlotResponse>>,
    setSelectedPapers: Dispatch<SetStateAction<ReadonlyArray<PaperData>>>,
    setPaperRightViewer: Dispatch<SetStateAction<PaperData>>
) => {

    const onHover = React.useCallback(function (this: ScicartaPoint) {
        const paper = { ...this.custom.data };

        Mixpanel.track({
            name: 'User hover on paper marker',
            props: paper
        });

        setTimeout(() => {
            setPaperLeftViewer(prev => {
                if (prev.pmid !== paper.pmid) {
                    console.log(paper);
                    return paper;
                }

                return prev;
            });
        }, 0);
        return false;
    }, [ setPaperLeftViewer ]);

    const onUpdate = React.useCallback((figure: Readonly<any>) => {
        // We need to pick the changes we are interested in and ensure there are differences before changing anything
        // else we will have a nasty loop

        setPlotResponse(prev => {
            if (prev && prev.layout && !deepEqual(figure.layout, prev.layout, { strict: true })) {
                return {
                    ...prev,
                    layout: { ...figure.layout }
                };
            }

            return prev;
        });
    }, [ setPlotResponse ]);

    const onClick = React.useCallback(function(this: ScicartaPoint) {
        const paper = this.custom.data;

        Mixpanel.track({
            name: 'User click in paper marker',
            props: paper
        });

        const existPaper = (obj: PaperData) => obj.pmid === paper.pmid;

        setSelectedPapers(prev => {
            if (!prev.some(existPaper)) {
                return [ paper, ...prev ];
            } else {
                return prev.filter(p => p.pmid !== paper.pmid);
            }
        });

        setPaperRightViewer(prev => {
            if (prev.pmid !== paper.pmid) {
                return paper;
            }

            return prev;
        });
    }, [ setSelectedPapers, setPaperRightViewer ]);

    return useMemo(() => ({
        onHover,
        onUpdate,
        onClick
    }), [ onHover, onUpdate, onClick ]);
};
