import * as React from 'react';
import { Alert, AlertActionCloseButton, AlertVariant, AlertGroup } from '@patternfly/react-core';

interface ViewerProps {
    alerts?: AlertData[];
    removeAlert: (index: number) => void;
}

export interface AlertData {
    key: number;
    title: string;
    body?: string;
    variant?: AlertVariant;
}

export const AlertList: React.FunctionComponent<ViewerProps> = (props) => {
    const toAlert = (alertKey: number) => {
        return (
            <AlertActionCloseButton
                onClose={ () => {
                    props.removeAlert(alertKey);
                } }
            />
        );
    };

    return (
        <React.Fragment>
            <AlertGroup isToast>
                {!(props.alerts) ? '' : props.alerts.map((alert) => (
                    <Alert
                        key={ alert.key }
                        title={ alert.title }
                        variant={ alert.variant }
                        actionClose={ toAlert(alert.key) }
                    >
                        <span dangerouslySetInnerHTML={ { __html: (alert.body) ? alert.body : '' } }/>
                    </Alert>

                ))}
            </AlertGroup>
        </React.Fragment>
    );
};
